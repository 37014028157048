import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

import { LegalFile } from '@models/legal-file';
@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private _legalFiles: BehaviorSubject<any>;
  private _dataStore: any = {};

  constructor(
    private http: HttpService) {
    this._legalFiles = new BehaviorSubject([]);
  }

  set legalFiles(value) {
    this._legalFiles.next(value);
  }

  get legalFiles() {
    return this._legalFiles.asObservable();
  }

  getData() {
    //TODO: Set correct types instead of 'any' and replace subscribe methods to component
    this.http.getData<any>('/legal-files/?type[$like]=TERMS%').subscribe(response => {
      this._dataStore.terms = [...response.data];
      this.legalFiles = this._dataStore;
    });
    this.http.getData<any>('/legal-files/?type[$like]=EMAIL%').subscribe(response => {
      this._dataStore.mail = [...response.data];
      this.legalFiles = this._dataStore;
    });
    this.http.getData<any>('/legal-files/?type[$like]=PRIVACY%').subscribe(response => {
      this._dataStore.privacy = [...response.data];
      this.legalFiles = this._dataStore;
    });
    this.http.getData<any>('/legal-files/?type[$like]=HEALTH%').subscribe(response => {
      this._dataStore.health = [...response.data];
      this.legalFiles = this._dataStore;
    });
  }

  getSingleDataPiece(id: number): Observable<LegalFile> {
    return this.http.getData<LegalFile>(`/legal-files/${id}`).pipe(
      map((response) => new LegalFile(response.content, response.title)),
    );

  }

  save(data, id) {
    return this.http.patchData(`/legal-files/${id}`, data);
  }
}
