import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Playlist } from '../../../../models/playlist';
import { UtvService } from '../../../../services/utv.service';

@Component({
  selector: 'app-video-playlists-view',
  templateUrl: './video-playlists-view.component.html',
  styleUrls: ['./video-playlists-view.component.scss'],
})
export class VideoPlaylistsViewComponent implements OnInit, OnDestroy {
  public playlist: Playlist;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private utvService: UtvService
  ) { }

  ngOnInit() {
    this.getPlaylist();
    this.utvService.getPlaylist(this.route.snapshot.params.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPlaylist() {
    this.utvService.playlist
      .pipe(
        filter((response: any) => {
          return response.created_at;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.playlist = result;
      });
  }
}
