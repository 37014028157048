<section *ngIf="companyDetails" class="details">
  <div class="details-header">
    <h2 class="details-header-title">
      {{ company.name }}
    </h2>
    <button class="cancel" appIcon="cancel" (click)="close()"></button>
  </div>

  <div class="details-content">
    <div class="details-content-general">
      <div class="general-header">
        <h3 class="general-header-title">
          {{ 'company.generalInformation' | translate | uppercase }}
        </h3>
        <p class="general-header-status">
          {{ 'company.status' | translate | uppercase }}:
          <span
            *ngIf="company.status"
            class="status"
            [class.trial]="company.status === COMPANY_TABS.TRIAL && !company.enabled"
            [class.enabled]="company.enabled"
          >
            <span>
              {{ !companyDetails.enabled ? (companyStatus | uppercase) : companyDetails.status | uppercase }}
            </span>

            <span
              *ngIf="company.status === COMPANY_TABS.TRIAL && !company.enabled"
            >
              {{ 'company.till' | translate }} {{ companyDetails.trial_period_expires_at | date: 'dd.MM.yyyy' }}
            </span>
          </span>
          <span *ngIf="!company.status" class="status">
            {{ 'company.na' | translate }}
          </span>
        </p>
      </div>

      <form class="general-form" *ngIf="detailsFG" [formGroup]="detailsFG">
        <div class="general-form-row">
          <div class="general-form-row-fields">
            <app-material-input
              formControlName="companyId"
              class="material-input"
              [styledDisabled]="false"
              [label]="'company.companyId' | translate"
            >
            </app-material-input>
            <app-material-input
              formControlName="companyName"
              class="material-input"
              [styledDisabled]="false"
              [label]="'company.companyName' | translate"
            >
            </app-material-input>
            <app-material-input
              formControlName="email"
              class="material-input"
              [styledDisabled]="false"
              [label]="'company.email' | translate"
            >
            </app-material-input>
            <app-material-input
              formControlName="country"
              class="material-input"
              [styledDisabled]="false"
              [label]="'company.country' | translate"
            >
            </app-material-input>
            <app-material-input
              formControlName="address"
              class="material-input"
              [styledDisabled]="false"
              [label]="'company.address' | translate"
            >
            </app-material-input>
          </div>

          <div *ngIf="company.logo; else defaultLogo" class="general-form-row-image">
            <img [src]="company.logo" alt="logo">
          </div>

          <ng-template #defaultLogo>
            <div class="default-logo-container">
              <img src="assets/logo.svg" alt="logo" width="130px">
            </div>
          </ng-template>
        </div>
        <div class="general-form-row">
          <app-material-textarea
            class="general-form-row-textarea"
            [styledDisabled]="false"
            [formControl]="detailsFG.get('description')"
            [label]="'company.description' | translate"
          ></app-material-textarea>
        </div>
      </form>

      <div class="general-actions">
        <button
          *ngIf="companyDetails.original_status !== COMPANY_TABS.ENABLED"
          class="enable"
          (click)="openEnableModal(company)"
        >
          {{ 'company.enable' | translate }}
        </button>

        <button
          *ngIf="companyDetails.original_status === COMPANY_TABS.ENABLED"
          class="disable"
          (click)="openDisableModal(company)"
        >
          {{ 'company.disable' | translate }}
        </button>

        <button
          class="delete"
          (click)="openDeleteModal(company)"
        >
          {{ 'company.delete' | translate }}
        </button>
      </div>
    </div>

    <div class="details-content-statistic">
      <h3 class="statistic-header">
        {{ 'company.statistic' | translate | uppercase }}
      </h3>

      <div class="statistic-row">
        <div class="statistic-row-small-card">
          <div class="small-card-data">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M16 20.4739C20.208 20.4739 23.687 23.5229 23.687 27.3769C23.687 28.1129 23.09 28.7099
              22.354 28.7099C21.618 28.7099 21.021 28.1129 21.021 27.3769C21.021 25.0799 18.808 23.1409
              15.999 23.1409C13.191 23.1409 10.979 25.0799 10.979 27.3769C10.979 28.1129 10.382 28.7099
              9.64602 28.7099C8.91002 28.7099 8.31302 28.1129 8.31302 27.3769C8.31302 23.5229 11.791
              20.4739 15.999 20.4739H16ZM25.06 21.2959C28.257 21.2959 30.867 23.6189 30.867 26.5229C30.867
              27.1369 30.369 27.6349 29.754 27.6349C29.187 27.6349 28.719 27.2109 28.65 26.6619L28.634
              26.3269C28.514 24.7769 26.97 23.5209 25.06 23.5209C24.516 23.5209 24.353 23.5399 23.827
              23.6589C23.164 23.8089 22.611 23.6359 22.419 23.0939C22.218 22.5229 22.456 21.9939 23.053
              21.6889C23.386 21.5189 23.654 21.4199 23.928 21.3639C24.144 21.3199 24.317 21.3039 24.657
              21.2979L25.06 21.2959ZM6.88002 21.2959L7.28202 21.2979C7.62202 21.3039 7.79502 21.3199
              8.01202 21.3639C8.28502 21.4199 8.55302 21.5199 8.88602 21.6889C9.48302 21.9929 9.72102
              22.5229 9.52002 23.0939C9.32802 23.6359 8.77502 23.8089 8.11202 23.6589C7.58602 23.5389
              7.42302 23.5209 6.87902 23.5209C4.88902 23.5209 3.29702 24.8829 3.29702 26.5229C3.29702
              27.1369 2.79902 27.6349 2.18502 27.6349C1.57002 27.6349 1.07202 27.1369 1.07202 26.5229C1.07202
              23.6189 3.68202 21.2959 6.87902 21.2959H6.88002ZM25.857 8.92588C28.593 8.92588 30.754 11.5509
              30.754 14.7609C30.754 17.9709 28.594 20.5949 25.857 20.5949C24.171 20.5949 23.173 20.1519
              22.162 19.0479C21.74 18.5869 21.702 17.9799 22.075 17.5079C22.442 17.0459 23.107 17.0989
              23.785 17.5639L24.144 17.8319C24.725 18.2559 25.089 18.3699 25.857 18.3699C27.297 18.3699
              28.529 16.7669 28.529 14.7609C28.529 12.8239 27.479 11.4559 25.954 11.1649C25.752 11.1249
              25.503 11.1069 25.207 11.1069H25.016C24.899 11.1059 24.813 11.0979 24.713 11.0799C24.3
              11.0019 24.022 10.7469 23.966 10.2729C23.882 9.56888 24.294 9.15988 24.866 9.02288C25.093
              8.96788 25.564 8.92688 25.857 8.92688V8.92588ZM6.08202 8.92588C6.37502 8.92588 6.84702 8.96788
              7.07202 9.02288C7.64502 9.15988 8.05702 9.56888 7.97402 10.2729C7.91702 10.7469 7.64002 11.0029
              7.22602 11.0799C7.12602 11.0979 7.04002 11.1059 6.92302 11.1069H6.73302C6.43602 11.1069 6.18702
              11.1259 5.98502 11.1649C4.46002 11.4559 3.41002 12.8249 3.41002 14.7609C3.41002 16.7669 4.64202
              18.3699 6.08202 18.3699C6.93502 18.3699 7.29002 18.2299 7.99802 17.6779C8.72802 17.1099 9.46902
              17.0099 9.86402 17.5079C10.238 17.9799 10.2 18.5869 9.77702 19.0479C8.76702 20.1519 7.76902
              20.5949 6.08202 20.5949C3.34602 20.5949 1.18502 17.9709 1.18502 14.7609C1.18502 11.5509 3.34602
              8.92688 6.08202 8.92688V8.92588ZM16 3.62988C19.64 3.62988 22.457 7.14588 22.457 11.3549C22.457
              15.5649 19.64 19.0809 16 19.0809C12.361 19.0809 9.54302 15.5639 9.54302 11.3549C9.54302 7.14588
              12.36 3.62988 16 3.62988ZM16 6.29688C13.98 6.29688 12.21 8.50588 12.21 11.3549C12.21 14.2049
              13.98 16.4149 16 16.4149C18.02 16.4149 19.79 14.2049 19.79 11.3549C19.79 8.50488 18.02 6.29688
              16 6.29688Z"
              fill="#14BFFB"/>
            </svg>
            <span class="small-card-data-value">
              {{ companyDetails.accepted_employees_count || 0 }}
            </span>
          </div>
          <p class="small-card-title">
            {{ 'company.acceptedEmployees' | translate }}
          </p>
        </div>
        <div class="statistic-row-small-card">
          <div class="small-card-data">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M16 20.4739C20.208 20.4739 23.687 23.5229 23.687 27.3769C23.687 28.1129 23.09 28.7099
              22.354 28.7099C21.618 28.7099 21.021 28.1129 21.021 27.3769C21.021 25.0799 18.808 23.1409
              15.999 23.1409C13.191 23.1409 10.979 25.0799 10.979 27.3769C10.979 28.1129 10.382 28.7099
              9.64602 28.7099C8.91002 28.7099 8.31302 28.1129 8.31302 27.3769C8.31302 23.5229 11.791
              20.4739 15.999 20.4739H16ZM25.06 21.2959C28.257 21.2959 30.867 23.6189 30.867 26.5229C30.867
              27.1369 30.369 27.6349 29.754 27.6349C29.187 27.6349 28.719 27.2109 28.65 26.6619L28.634
              26.3269C28.514 24.7769 26.97 23.5209 25.06 23.5209C24.516 23.5209 24.353 23.5399 23.827
              23.6589C23.164 23.8089 22.611 23.6359 22.419 23.0939C22.218 22.5229 22.456 21.9939 23.053
              21.6889C23.386 21.5189 23.654 21.4199 23.928 21.3639C24.144 21.3199 24.317 21.3039 24.657
              21.2979L25.06 21.2959ZM6.88002 21.2959L7.28202 21.2979C7.62202 21.3039 7.79502 21.3199
              8.01202 21.3639C8.28502 21.4199 8.55302 21.5199 8.88602 21.6889C9.48302 21.9929 9.72102
              22.5229 9.52002 23.0939C9.32802 23.6359 8.77502 23.8089 8.11202 23.6589C7.58602 23.5389
              7.42302 23.5209 6.87902 23.5209C4.88902 23.5209 3.29702 24.8829 3.29702 26.5229C3.29702
              27.1369 2.79902 27.6349 2.18502 27.6349C1.57002 27.6349 1.07202 27.1369 1.07202 26.5229C1.07202
              23.6189 3.68202 21.2959 6.87902 21.2959H6.88002ZM25.857 8.92588C28.593 8.92588 30.754 11.5509
              30.754 14.7609C30.754 17.9709 28.594 20.5949 25.857 20.5949C24.171 20.5949 23.173 20.1519
              22.162 19.0479C21.74 18.5869 21.702 17.9799 22.075 17.5079C22.442 17.0459 23.107 17.0989
              23.785 17.5639L24.144 17.8319C24.725 18.2559 25.089 18.3699 25.857 18.3699C27.297 18.3699
              28.529 16.7669 28.529 14.7609C28.529 12.8239 27.479 11.4559 25.954 11.1649C25.752 11.1249
              25.503 11.1069 25.207 11.1069H25.016C24.899 11.1059 24.813 11.0979 24.713 11.0799C24.3
              11.0019 24.022 10.7469 23.966 10.2729C23.882 9.56888 24.294 9.15988 24.866 9.02288C25.093
              8.96788 25.564 8.92688 25.857 8.92688V8.92588ZM6.08202 8.92588C6.37502 8.92588 6.84702 8.96788
              7.07202 9.02288C7.64502 9.15988 8.05702 9.56888 7.97402 10.2729C7.91702 10.7469 7.64002 11.0029
              7.22602 11.0799C7.12602 11.0979 7.04002 11.1059 6.92302 11.1069H6.73302C6.43602 11.1069 6.18702
              11.1259 5.98502 11.1649C4.46002 11.4559 3.41002 12.8249 3.41002 14.7609C3.41002 16.7669 4.64202
              18.3699 6.08202 18.3699C6.93502 18.3699 7.29002 18.2299 7.99802 17.6779C8.72802 17.1099 9.46902
              17.0099 9.86402 17.5079C10.238 17.9799 10.2 18.5869 9.77702 19.0479C8.76702 20.1519 7.76902
              20.5949 6.08202 20.5949C3.34602 20.5949 1.18502 17.9709 1.18502 14.7609C1.18502 11.5509 3.34602
              8.92688 6.08202 8.92688V8.92588ZM16 3.62988C19.64 3.62988 22.457 7.14588 22.457 11.3549C22.457
              15.5649 19.64 19.0809 16 19.0809C12.361 19.0809 9.54302 15.5639 9.54302 11.3549C9.54302 7.14588
              12.36 3.62988 16 3.62988ZM16 6.29688C13.98 6.29688 12.21 8.50588 12.21 11.3549C12.21 14.2049
              13.98 16.4149 16 16.4149C18.02 16.4149 19.79 14.2049 19.79 11.3549C19.79 8.50488 18.02 6.29688
              16 6.29688Z"
              fill="#9899A0"/>
            </svg>
            <span class="small-card-data-value">
              {{ companyDetails.pending_employees_count || 0 }}
            </span>
          </div>
          <p class="small-card-title">
            {{ 'company.pendingEmployees' | translate }}
          </p>
        </div>
      </div>

      <div class="statistic-row">
        <div class="statistic-row-small-card">
          <div class="small-card-data">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.1322 1.33301C22.0126 1.33301 23.0451 1.8178 23.5919 2.51477L29.9993
                10.5446C30.546 11.2112 30.789 12.3325 30.6067 13.181L28.3291 23.1803C28.1471
                24.0288 27.4183 24.9076 26.6285 25.3016L17.3666 29.7257C16.8808 29.968 16.334
                30.0287 16.0001 30.0287C15.6354 30.0287 15.1195 29.968 14.6334 29.7561L5.37148
                25.3016C4.58171 24.9379 3.88339 24.0291 3.67085 23.1806L1.39298 13.181C1.21092
                12.3325 1.45395 11.2112 2.00069 10.5446L8.40808 2.51477C8.95482 1.8178 9.98734
                1.33301 10.868 1.33301H21.1322ZM10.5227 11.3574C8.32428 11.3574 7.16377 12.4662
                7.07238 14.6533V21.7388H8.0189C8.62966 21.7388 8.93476 21.4303 8.93476
                20.8143V17.8264C9.39283 18.5963 10.0953 18.966 11.0111 18.966C12.9654 18.9043
                13.9423 17.5798 13.9423 15.0539C13.9423 12.6202 12.8128 11.3574 10.5227
                11.3574ZM17.7593 11.3263C16.0493 11.3263 15.1026 12.004 14.8888
                13.3593H16.7211C16.8125 13.0205 17.1789 12.8356 17.8203 12.8356C18.5838 12.8356
                18.9806 13.0514 19.0109 13.4516C19.0109 13.8522 18.6142 14.0987 17.8203
                14.191C15.9577 14.3759 14.6751 14.8379 14.6448 16.6247C14.6448 18.2572 15.866
                19.0889 17.6373 18.9658C19.6217 18.8118 20.8432 17.9184 20.8432 15.8544V13.329C20.8126
                12.0045 19.7744 11.3268 17.759 11.3268L17.7593 11.3263ZM22.7362 11.4497H21.7897V18.8737H23.6521V12.3431C23.6214
                11.7268 23.3163 11.4497 22.7362 11.4497ZM19.0416 15.1156V15.8241H19.0722C19.0722 16.8712
                18.4308 17.4875 17.4233 17.4875C16.8432 17.4875 16.5378 17.2104 16.4768 16.625C16.4768
                16.0704 16.8432 15.7316 17.576 15.5776C18.1254 15.4541 18.6142 15.3004 19.0416 15.1156ZM10.5227
                12.9282C11.5912 12.9282 12.1103 13.6676 12.1103 15.177C12.0189 16.625 11.4998 17.3335 10.5227
                17.3335C9.51487 17.3335 8.99578 16.625 8.96513 15.177C8.96513 13.6679 9.45385 12.9282 10.5227
                12.9282ZM22.5835 8.98518H21.4537V9.6323C21.423 10.3409 21.7897 10.7103 22.4919
                10.7103H23.6217V10.0634C23.6524 9.35488 23.2857 8.98518 22.5835 8.98518Z"
                fill="#14BFFB"/>
            </svg>
            <span class="small-card-data-value">
              {{ companyDetails?.pai_share_employees_count || 0 }}
            </span>
          </div>
          <p class="small-card-title">
            {{ 'company.registeredAndSharePAI' | translate }}
          </p>
        </div>
        <div class="statistic-row-small-card">
          <div class="small-card-data">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.0181 14.1992H8.96584C7.97372 14.1992 7.16674 13.3954 7.16674 12.4001C7.16674
                11.4034 7.98452 10.5856 8.98282 10.5856H10.0351C11.0272 10.5856
                11.8342 11.391 11.8342 12.3847C11.8342 13.383 11.0149 14.1992 10.0181 14.1992ZM16.5893
                14.1992H15.537C14.5449 14.1992 13.7395 13.3954 13.7395 12.4001C13.7395 11.4034 14.5572
                10.5856 15.554 10.5856H16.6063C17.6 10.5856 18.4054 11.391 18.4054 12.3847C18.4054
                13.383 17.5876 14.1992 16.5893 14.1992ZM23.1621 14.1992H22.1098C21.1161 14.1992
                20.3107 13.3954 20.3107 12.4001C20.3107 11.4034 21.1284 10.5856 22.1267 10.5856H23.179C24.1712
                10.5856 24.9766 11.391 24.9766 12.3847C24.9766 13.383 24.1588 14.1992 23.1621 14.1992ZM10.0181
                19.0662H8.96584C7.97372 19.0662 7.16674 18.2608 7.16674 17.2671C7.16674 16.2688 7.98452
                15.451 8.98282 15.451H10.0351C11.0272 15.451 11.8342 16.258 11.8342 17.2501C11.8342 18.25
                11.0149 19.0662 10.0181 19.0662ZM16.5893 19.0662H15.537C14.5449 19.0662 13.7395 18.2608
                13.7395 17.2671C13.7395 16.2688 14.5572 15.451 15.554 15.451H16.6063C17.6 15.451 18.4054
                16.258 18.4054 17.2501C18.4054 18.25 17.5876 19.0662 16.5893 19.0662ZM23.1621
                19.0662H22.1098C21.1161 19.0662 20.3107 18.2608 20.3107 17.2671C20.3107 16.2688 21.1284
                15.451 22.1267 15.451H23.179C24.1712 15.451 24.9766 16.258 24.9766 17.2501C24.9766 18.25
                24.1588 19.0662 23.1621 19.0662ZM10.0181 24.0822H8.96584C7.97372 24.0822 7.16674 23.2784
                7.16674 22.2831C7.16674 21.2864 7.98452 20.4671 8.98282 20.4671H10.0351C11.0272 20.4671
                11.8342 21.274 11.8342 22.2662C11.8342 23.266 11.0149 24.0822 10.0181 24.0822ZM16.5893
                24.0822H15.537C14.5449 24.0822 13.7395 23.2784 13.7395 22.2831C13.7395 21.2864 14.5572
                20.4671 15.554 20.4671H16.6063C17.6 20.4671 18.4054 21.274 18.4054 22.2662C18.4054 23.266
                17.5876 24.0822 16.5893 24.0822ZM23.1621 24.0822H22.1098C21.1161 24.0822 20.3107 23.2784
                20.3107 22.2831C20.3107 21.2864 21.1284 20.4671 22.1267 20.4671H23.179C24.1712 20.4671
                24.9766 21.274 24.9766 22.2662C24.9766 23.266 24.1588 24.0822 23.1621 24.0822ZM22.2053
                7.57683H22.0386C21.1267 7.57683 20.3861 6.83775 20.3861 5.92431V2.98496C20.3861 2.07461
                21.1267 1.33398 22.0386 1.33398H22.2053C23.1187 1.33398 23.8578 2.07461 23.8578
                2.98496V5.92431C23.8578 6.83775 23.1187 7.57683 22.2053 7.57683ZM10.0558 7.57683H9.8907C8.97726
                7.57683 8.23664 6.83775 8.23664 5.92431V2.98496C8.23664 2.07461 8.97726 1.33398 9.8907
                1.33398H10.0558C10.9692 1.33398 11.7083 2.07461 11.7083 2.98496V5.92431C11.7083 6.83775
                10.9692 7.57683 10.0558 7.57683ZM27.5807 7.56618H26.263C25.4108 7.56618 24.72 6.87537 24.72
                6.02321C24.72 5.17106 25.4108 4.48025 26.263 4.48025H29.1236C29.9758 4.48025 30.6666 5.17106
                30.6666 6.02321V28.7326C30.6666 29.5847 29.9758 30.2756 29.1236 30.2756H2.87622C2.02406 30.2756
                1.33325 29.5847 1.33325 28.7326V6.02321C1.33325 5.17106 2.02406 4.48025 2.87622
                4.48025H5.75385C6.60601 4.48025 7.29682 5.17106 7.29682 6.02321C7.29682 6.87537 6.60601
                7.56618 5.75385 7.56618H4.41919V27.1896H27.5807V7.56618ZM14.2524 7.56618C13.4002 7.56618
                12.7094 6.87537 12.7094 6.02321C12.7094 5.17106 13.4002 4.48025 14.2524 4.48025H18.045C18.8971
                4.48025 19.5879 5.17106 19.5879 6.02321C19.5879 6.87537 18.8971 7.56618 18.045 7.56618H14.2524Z"
                fill="#14BFFB"/>
            </svg>
            <span class="small-card-data-value">
              {{ companyDetails.last_action_at | date: 'dd.MM.yyyy' }}
            </span>
          </div>
          <p class="small-card-title">
            {{ 'company.lastActivityDate' | translate }}
          </p>
        </div>
      </div>

      <div class="statistic-row">
        <div class="statistic-row-big-card">
          <div class="big-card-header">
            <button
              class="icon-btn"
              appIcon="arrow_left"
              [disabled]="isDisableWeekBack"
              (click)="switchWeekBack()"
            ></button>
            <span>
              {{ 'company.chosenWeek' | translate }},
              {{ weekStartDate | date: 'dd.MM.yy' }} - {{ weekEndDate | date: 'dd.MM.yy' }}
            </span>
            <button
              class="icon-btn"
              appIcon="arrow_right"
              [disabled]="isDisableWeekForward"
              (click)="switchWeekForward()"
            ></button>
          </div>
          <div class="big-card-content">
            <div class="big-card-content-cell">
              <div class="big-card-content-cell-data">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M16 20.4739C20.208 20.4739 23.687 23.5229 23.687 27.3769C23.687 28.1129 23.09 28.7099
                  22.354 28.7099C21.618 28.7099 21.021 28.1129 21.021 27.3769C21.021 25.0799 18.808 23.1409
                  15.999 23.1409C13.191 23.1409 10.979 25.0799 10.979 27.3769C10.979 28.1129 10.382 28.7099
                  9.64602 28.7099C8.91002 28.7099 8.31302 28.1129 8.31302 27.3769C8.31302 23.5229 11.791
                  20.4739 15.999 20.4739H16ZM25.06 21.2959C28.257 21.2959 30.867 23.6189 30.867 26.5229C30.867
                  27.1369 30.369 27.6349 29.754 27.6349C29.187 27.6349 28.719 27.2109 28.65 26.6619L28.634
                  26.3269C28.514 24.7769 26.97 23.5209 25.06 23.5209C24.516 23.5209 24.353 23.5399 23.827
                  23.6589C23.164 23.8089 22.611 23.6359 22.419 23.0939C22.218 22.5229 22.456 21.9939 23.053
                  21.6889C23.386 21.5189 23.654 21.4199 23.928 21.3639C24.144 21.3199 24.317 21.3039 24.657
                  21.2979L25.06 21.2959ZM6.88002 21.2959L7.28202 21.2979C7.62202 21.3039 7.79502 21.3199
                  8.01202 21.3639C8.28502 21.4199 8.55302 21.5199 8.88602 21.6889C9.48302 21.9929 9.72102
                  22.5229 9.52002 23.0939C9.32802 23.6359 8.77502 23.8089 8.11202 23.6589C7.58602 23.5389
                  7.42302 23.5209 6.87902 23.5209C4.88902 23.5209 3.29702 24.8829 3.29702 26.5229C3.29702
                  27.1369 2.79902 27.6349 2.18502 27.6349C1.57002 27.6349 1.07202 27.1369 1.07202 26.5229C1.07202
                  23.6189 3.68202 21.2959 6.87902 21.2959H6.88002ZM25.857 8.92588C28.593 8.92588 30.754 11.5509
                  30.754 14.7609C30.754 17.9709 28.594 20.5949 25.857 20.5949C24.171 20.5949 23.173 20.1519
                  22.162 19.0479C21.74 18.5869 21.702 17.9799 22.075 17.5079C22.442 17.0459 23.107 17.0989
                  23.785 17.5639L24.144 17.8319C24.725 18.2559 25.089 18.3699 25.857 18.3699C27.297 18.3699
                  28.529 16.7669 28.529 14.7609C28.529 12.8239 27.479 11.4559 25.954 11.1649C25.752 11.1249
                  25.503 11.1069 25.207 11.1069H25.016C24.899 11.1059 24.813 11.0979 24.713 11.0799C24.3
                  11.0019 24.022 10.7469 23.966 10.2729C23.882 9.56888 24.294 9.15988 24.866 9.02288C25.093
                  8.96788 25.564 8.92688 25.857 8.92688V8.92588ZM6.08202 8.92588C6.37502 8.92588 6.84702 8.96788
                  7.07202 9.02288C7.64502 9.15988 8.05702 9.56888 7.97402 10.2729C7.91702 10.7469 7.64002 11.0029
                  7.22602 11.0799C7.12602 11.0979 7.04002 11.1059 6.92302 11.1069H6.73302C6.43602 11.1069 6.18702
                  11.1259 5.98502 11.1649C4.46002 11.4559 3.41002 12.8249 3.41002 14.7609C3.41002 16.7669 4.64202
                  18.3699 6.08202 18.3699C6.93502 18.3699 7.29002 18.2299 7.99802 17.6779C8.72802 17.1099 9.46902
                  17.0099 9.86402 17.5079C10.238 17.9799 10.2 18.5869 9.77702 19.0479C8.76702 20.1519 7.76902
                  20.5949 6.08202 20.5949C3.34602 20.5949 1.18502 17.9709 1.18502 14.7609C1.18502 11.5509 3.34602
                  8.92688 6.08202 8.92688V8.92588ZM16 3.62988C19.64 3.62988 22.457 7.14588 22.457 11.3549C22.457
                  15.5649 19.64 19.0809 16 19.0809C12.361 19.0809 9.54302 15.5639 9.54302 11.3549C9.54302 7.14588
                  12.36 3.62988 16 3.62988ZM16 6.29688C13.98 6.29688 12.21 8.50588 12.21 11.3549C12.21 14.2049
                  13.98 16.4149 16 16.4149C18.02 16.4149 19.79 14.2049 19.79 11.3549C19.79 8.50488 18.02 6.29688
                  16 6.29688Z"
                  fill="#14BFFB"/>
                </svg>
                <span class="big-card-content-cell-data-value">
                  {{ weekStatistic?.open_app_count || 0 }}
                </span>
              </div>
              <p class="big-card-content-cell-title">
                {{ 'company.openedAppOnChosenWeek' | translate }}
              </p>
            </div>

            <div class="big-card-content-cell">
              <div class="big-card-content-cell-data">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.1322 1.33301C22.0126 1.33301 23.0451 1.8178 23.5919 2.51477L29.9993
                    10.5446C30.546 11.2112 30.789 12.3325 30.6067 13.181L28.3291 23.1803C28.1471
                    24.0288 27.4183 24.9076 26.6285 25.3016L17.3666 29.7257C16.8808 29.968 16.334
                    30.0287 16.0001 30.0287C15.6354 30.0287 15.1195 29.968 14.6334 29.7561L5.37148
                    25.3016C4.58171 24.9379 3.88339 24.0291 3.67085 23.1806L1.39298 13.181C1.21092
                    12.3325 1.45395 11.2112 2.00069 10.5446L8.40808 2.51477C8.95482 1.8178 9.98734
                    1.33301 10.868 1.33301H21.1322ZM10.5227 11.3574C8.32428 11.3574 7.16377 12.4662
                    7.07238 14.6533V21.7388H8.0189C8.62966 21.7388 8.93476 21.4303 8.93476
                    20.8143V17.8264C9.39283 18.5963 10.0953 18.966 11.0111 18.966C12.9654 18.9043
                    13.9423 17.5798 13.9423 15.0539C13.9423 12.6202 12.8128 11.3574 10.5227
                    11.3574ZM17.7593 11.3263C16.0493 11.3263 15.1026 12.004 14.8888
                    13.3593H16.7211C16.8125 13.0205 17.1789 12.8356 17.8203 12.8356C18.5838 12.8356
                    18.9806 13.0514 19.0109 13.4516C19.0109 13.8522 18.6142 14.0987 17.8203
                    14.191C15.9577 14.3759 14.6751 14.8379 14.6448 16.6247C14.6448 18.2572 15.866
                    19.0889 17.6373 18.9658C19.6217 18.8118 20.8432 17.9184 20.8432 15.8544V13.329C20.8126
                    12.0045 19.7744 11.3268 17.759 11.3268L17.7593 11.3263ZM22.7362 11.4497H21.7897V18.8737H23.6521V12.3431C23.6214
                    11.7268 23.3163 11.4497 22.7362 11.4497ZM19.0416 15.1156V15.8241H19.0722C19.0722 16.8712
                    18.4308 17.4875 17.4233 17.4875C16.8432 17.4875 16.5378 17.2104 16.4768 16.625C16.4768
                    16.0704 16.8432 15.7316 17.576 15.5776C18.1254 15.4541 18.6142 15.3004 19.0416 15.1156ZM10.5227
                    12.9282C11.5912 12.9282 12.1103 13.6676 12.1103 15.177C12.0189 16.625 11.4998 17.3335 10.5227
                    17.3335C9.51487 17.3335 8.99578 16.625 8.96513 15.177C8.96513 13.6679 9.45385 12.9282 10.5227
                    12.9282ZM22.5835 8.98518H21.4537V9.6323C21.423 10.3409 21.7897 10.7103 22.4919
                    10.7103H23.6217V10.0634C23.6524 9.35488 23.2857 8.98518 22.5835 8.98518Z"
                    fill="#14BFFB"/>
                </svg>
                <span class="big-card-content-cell-data-value">
                  {{ weekStatistic?.pai_sync_count || 0 }}
                </span>
              </div>
              <p class="big-card-content-cell-title">
                {{ 'company.syncedDataOnChosenWeek' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="statistic-row">
        <div class="statistic-row-big-card">
          <div class="big-card-header">
            <button
              class="icon-btn"
              appIcon="arrow_left"
              [disabled]="isDisableMonthBack"
              (click)="switchMonthBack()"
            ></button>
            <span>
              {{ 'company.chosenMonth' | translate }},
              {{ monthEndDate | date: 'MMMM YYYY': '': currentLang }}
            </span>
            <button
              class="icon-btn"
              appIcon="arrow_right"
              [disabled]="isDisableMonthForward"
              (click)="switchMonthForward()"
            ></button>
          </div>
          <div class="big-card-content">

            <div class="big-card-content-cell">
              <div class="big-card-content-cell-data">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                  d="M16 20.4739C20.208 20.4739 23.687 23.5229 23.687 27.3769C23.687 28.1129 23.09 28.7099
                  22.354 28.7099C21.618 28.7099 21.021 28.1129 21.021 27.3769C21.021 25.0799 18.808 23.1409
                  15.999 23.1409C13.191 23.1409 10.979 25.0799 10.979 27.3769C10.979 28.1129 10.382 28.7099
                  9.64602 28.7099C8.91002 28.7099 8.31302 28.1129 8.31302 27.3769C8.31302 23.5229 11.791
                  20.4739 15.999 20.4739H16ZM25.06 21.2959C28.257 21.2959 30.867 23.6189 30.867 26.5229C30.867
                  27.1369 30.369 27.6349 29.754 27.6349C29.187 27.6349 28.719 27.2109 28.65 26.6619L28.634
                  26.3269C28.514 24.7769 26.97 23.5209 25.06 23.5209C24.516 23.5209 24.353 23.5399 23.827
                  23.6589C23.164 23.8089 22.611 23.6359 22.419 23.0939C22.218 22.5229 22.456 21.9939 23.053
                  21.6889C23.386 21.5189 23.654 21.4199 23.928 21.3639C24.144 21.3199 24.317 21.3039 24.657
                  21.2979L25.06 21.2959ZM6.88002 21.2959L7.28202 21.2979C7.62202 21.3039 7.79502 21.3199
                  8.01202 21.3639C8.28502 21.4199 8.55302 21.5199 8.88602 21.6889C9.48302 21.9929 9.72102
                  22.5229 9.52002 23.0939C9.32802 23.6359 8.77502 23.8089 8.11202 23.6589C7.58602 23.5389
                  7.42302 23.5209 6.87902 23.5209C4.88902 23.5209 3.29702 24.8829 3.29702 26.5229C3.29702
                  27.1369 2.79902 27.6349 2.18502 27.6349C1.57002 27.6349 1.07202 27.1369 1.07202 26.5229C1.07202
                  23.6189 3.68202 21.2959 6.87902 21.2959H6.88002ZM25.857 8.92588C28.593 8.92588 30.754 11.5509
                  30.754 14.7609C30.754 17.9709 28.594 20.5949 25.857 20.5949C24.171 20.5949 23.173 20.1519
                  22.162 19.0479C21.74 18.5869 21.702 17.9799 22.075 17.5079C22.442 17.0459 23.107 17.0989
                  23.785 17.5639L24.144 17.8319C24.725 18.2559 25.089 18.3699 25.857 18.3699C27.297 18.3699
                  28.529 16.7669 28.529 14.7609C28.529 12.8239 27.479 11.4559 25.954 11.1649C25.752 11.1249
                  25.503 11.1069 25.207 11.1069H25.016C24.899 11.1059 24.813 11.0979 24.713 11.0799C24.3
                  11.0019 24.022 10.7469 23.966 10.2729C23.882 9.56888 24.294 9.15988 24.866 9.02288C25.093
                  8.96788 25.564 8.92688 25.857 8.92688V8.92588ZM6.08202 8.92588C6.37502 8.92588 6.84702 8.96788
                  7.07202 9.02288C7.64502 9.15988 8.05702 9.56888 7.97402 10.2729C7.91702 10.7469 7.64002 11.0029
                  7.22602 11.0799C7.12602 11.0979 7.04002 11.1059 6.92302 11.1069H6.73302C6.43602 11.1069 6.18702
                  11.1259 5.98502 11.1649C4.46002 11.4559 3.41002 12.8249 3.41002 14.7609C3.41002 16.7669 4.64202
                  18.3699 6.08202 18.3699C6.93502 18.3699 7.29002 18.2299 7.99802 17.6779C8.72802 17.1099 9.46902
                  17.0099 9.86402 17.5079C10.238 17.9799 10.2 18.5869 9.77702 19.0479C8.76702 20.1519 7.76902
                  20.5949 6.08202 20.5949C3.34602 20.5949 1.18502 17.9709 1.18502 14.7609C1.18502 11.5509 3.34602
                  8.92688 6.08202 8.92688V8.92588ZM16 3.62988C19.64 3.62988 22.457 7.14588 22.457 11.3549C22.457
                  15.5649 19.64 19.0809 16 19.0809C12.361 19.0809 9.54302 15.5639 9.54302 11.3549C9.54302 7.14588
                  12.36 3.62988 16 3.62988ZM16 6.29688C13.98 6.29688 12.21 8.50588 12.21 11.3549C12.21 14.2049
                  13.98 16.4149 16 16.4149C18.02 16.4149 19.79 14.2049 19.79 11.3549C19.79 8.50488 18.02 6.29688
                  16 6.29688Z"
                  fill="#14BFFB"/>
                </svg>
                <span class="big-card-content-cell-data-value">
                  {{ monthStatistic?.open_app_count || 0 }}
                </span>
              </div>
              <p class="big-card-content-cell-title">
                {{ 'company.openedAppOnChosenWeekMonth' | translate }}
              </p>
            </div>

            <div class="big-card-content-cell">
              <div class="big-card-content-cell-data">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.1322 1.33301C22.0126 1.33301 23.0451 1.8178 23.5919 2.51477L29.9993
                    10.5446C30.546 11.2112 30.789 12.3325 30.6067 13.181L28.3291 23.1803C28.1471
                    24.0288 27.4183 24.9076 26.6285 25.3016L17.3666 29.7257C16.8808 29.968 16.334
                    30.0287 16.0001 30.0287C15.6354 30.0287 15.1195 29.968 14.6334 29.7561L5.37148
                    25.3016C4.58171 24.9379 3.88339 24.0291 3.67085 23.1806L1.39298 13.181C1.21092
                    12.3325 1.45395 11.2112 2.00069 10.5446L8.40808 2.51477C8.95482 1.8178 9.98734
                    1.33301 10.868 1.33301H21.1322ZM10.5227 11.3574C8.32428 11.3574 7.16377 12.4662
                    7.07238 14.6533V21.7388H8.0189C8.62966 21.7388 8.93476 21.4303 8.93476
                    20.8143V17.8264C9.39283 18.5963 10.0953 18.966 11.0111 18.966C12.9654 18.9043
                    13.9423 17.5798 13.9423 15.0539C13.9423 12.6202 12.8128 11.3574 10.5227
                    11.3574ZM17.7593 11.3263C16.0493 11.3263 15.1026 12.004 14.8888
                    13.3593H16.7211C16.8125 13.0205 17.1789 12.8356 17.8203 12.8356C18.5838 12.8356
                    18.9806 13.0514 19.0109 13.4516C19.0109 13.8522 18.6142 14.0987 17.8203
                    14.191C15.9577 14.3759 14.6751 14.8379 14.6448 16.6247C14.6448 18.2572 15.866
                    19.0889 17.6373 18.9658C19.6217 18.8118 20.8432 17.9184 20.8432 15.8544V13.329C20.8126
                    12.0045 19.7744 11.3268 17.759 11.3268L17.7593 11.3263ZM22.7362 11.4497H21.7897V18.8737H23.6521V12.3431C23.6214
                    11.7268 23.3163 11.4497 22.7362 11.4497ZM19.0416 15.1156V15.8241H19.0722C19.0722 16.8712
                    18.4308 17.4875 17.4233 17.4875C16.8432 17.4875 16.5378 17.2104 16.4768 16.625C16.4768
                    16.0704 16.8432 15.7316 17.576 15.5776C18.1254 15.4541 18.6142 15.3004 19.0416 15.1156ZM10.5227
                    12.9282C11.5912 12.9282 12.1103 13.6676 12.1103 15.177C12.0189 16.625 11.4998 17.3335 10.5227
                    17.3335C9.51487 17.3335 8.99578 16.625 8.96513 15.177C8.96513 13.6679 9.45385 12.9282 10.5227
                    12.9282ZM22.5835 8.98518H21.4537V9.6323C21.423 10.3409 21.7897 10.7103 22.4919
                    10.7103H23.6217V10.0634C23.6524 9.35488 23.2857 8.98518 22.5835 8.98518Z"
                    fill="#14BFFB"/>
                </svg>
                <span class="big-card-content-cell-data-value">
                  {{ monthStatistic?.pai_sync_count || 0 }}
                </span>
              </div>
              <p class="big-card-content-cell-title">
                {{ 'company.syncedDataOnChosenMonth' | translate }}
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
