import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Trainer } from '../models/trainer';
import { User } from '../models/user';

@Injectable()
export class DataService {
  private _usersList: BehaviorSubject<any[]>;
  private _admin: BehaviorSubject<any>;

  private dataStore: {
    paymentHistory: any[],
    admin: any,
    priceList: any,
    userList: any
  };

  constructor(
  ) {
    this.dataStore = {
      paymentHistory: [],
      priceList: {},
      userList: [],
      admin: new User(),
    };
    this._admin = <BehaviorSubject<User>>new BehaviorSubject({});
    this._usersList = <BehaviorSubject<Trainer[]>>new BehaviorSubject([]);
  }

  refreshAllInfo() {
  }


  setAdmin(admin) {
    window.localStorage.setItem('mem-admin', JSON.stringify(admin));
    this.dataStore.admin = admin;
    this._admin.next(Object.assign({}, this.dataStore).admin);
  }

  getAdmin() {
    if (Object.keys(this.dataStore.admin).length < 1 && window.localStorage.getItem('mem-admin')) {
      this.dataStore.admin = JSON.parse(window.localStorage.getItem('mem-admin'));
      this._admin.next(Object.assign({}, this.dataStore).admin);
    } else if (Object.keys(this.dataStore.admin).length < 1) {
      this.dataStore.admin = new User();
      this._admin.next(Object.assign({}, this.dataStore).admin);
    }
    return this._admin.asObservable();
  }
}
