import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PricingService } from '../../../services/pricing.service';
import { TrainerLevel } from '../../../models/trainer-level';
import { Duration } from '../../../models/duration';

@Component({
  selector: 'app-duration-modal',
  templateUrl: './duration-modal.component.html',
  styleUrls: ['./duration-modal.component.scss'],
})
export class DurationModalComponent implements OnInit {
  durationForm: any;
  isEdit = false;
  levelList: TrainerLevel[];
  attention = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Duration,
    private pricingService: PricingService
  ) {}

  ngOnInit() {
    if (!this.data) {
      this.data = {
        duration: null,
        price: [],
        trainer_level: null,
      };
    } else {
      this.isEdit = true;
    }
    this.pricingService.levelList.subscribe(list => {
      this.levelList = list.data;
    });

    this.durationForm = new UntypedFormGroup({
      duration: new UntypedFormControl(this.data.duration, [Validators.required, Validators.min(0), Validators.max(1000)]),
      trainer_level: new UntypedFormControl(this.data.trainer_level, [Validators.required]),
      price: new UntypedFormArray([
        new UntypedFormControl(this.data.price[0], [Validators.required, Validators.min(0)]),
        new UntypedFormControl(this.data.price[1], [Validators.required, Validators.min(0)]),
      ], [Validators.required, Validators.min(0)]),
    });

  }

  save() {
    const data = Object.assign({}, this.durationForm.value);
    data.level_id = data.trainer_level.id;
    data.trainer_level = undefined;
    if (this.isEdit) {
      this.pricingService.edit(data, 'durations', this.data.id);
    } else {
      this.pricingService.create(data, 'durations');
    }
  }

  remove() {
    this.pricingService.remove('durations', this.data.id);
  }
}
