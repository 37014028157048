export enum PROFILE_ENUM {
  TRAINER_TYPE = 'trainer',
  PARTNER_TYPE = 'partner',
  CLIENT_TYPE = 'client',
  ACCOUNT_BUSINESS = 'business',
  ACCOUNT_FOUNDATION = 'foundation',
  ACCOUNT_INDIVIDUAL = 'individual',
}

export enum REGIONS {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export enum REGION {
  LIMIT = 7000,
}

export enum SESSION {
  TRAINING_TYPE = 'training',
  EVENT_TYPE = 'event',
  HIKING_ACTIVITY = 'hiking',
}

export enum CERTIFICATE_LIST_STATES {
  ADD      = 'add',
  DELETE   = 'delete',
  EDIT     = 'edit',
  DISABLED = 'disabled',
}

export enum TRAINER_TABS {
  PERSONAL = 'PERSONAL',
  EDUCATION = 'EDUCATION',
  SPECIALITY = 'SPECIALITY',
}

export enum SUBSCRIPTION_TYPES {
  FREE = 'FREE',
  UTV = 'UTV',
  UTVPLUS = 'UTV+',
}

export enum VIDEO_STATUSES {
  PUBLISHED = 'PUBLISHED',
  HIDDEN = 'HIDDEN',
}

export enum UPLOAD_LIMITS {
  VIDEO_MAX_SIZE = 4e+9,
  VIDEO_MAX_SIZE_STR = '4GB',
  VIDEO_MAX_NUMBER = 10,
  IMG_MAX_SIZE = 10e+6,
  IMG_MAX_SIZE_STR = '10MB',
}

export enum COMPANY_TABS {
  TRIAL = 'TRIAL',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
