<div class="modal-wrapper playlist-modal">
  <div class="overlay" routerLink="/utv"></div>
  <div class="card" *ngIf="playlist">
    <div class="card-header">
      <div class="title">
        {{'utv.view_playlist' | translate}}
      </div>
      <div class="close" routerLink="/utv"></div>
    </div>

    <div class="modal-body">
      <section class="modal-body-section">
        <div class="playlist-image">
          <img *ngIf="playlist.image" [src]="playlist.image">
        </div>
        <div class="playlist-name">
          {{playlist.name}}
        </div>
        <div class="playlist-description">
          {{playlist.description}}
        </div>
      </section>

      <section class="modal-body-section">
        <div class="playlist-content">
          <div *ngFor="let video of playlist.videos" class="playlist-video">
            <div class="playlist-video-image">
              <img *ngIf="video.thumbnail_url" [src]="video.thumbnail_url">
            </div>
            <div class="playlist-video-name">
              {{video.name}}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
