import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { localStorage } from '../utils/storage';
import { environment } from '../../environments/environment';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const additionalHeaders: any = req.headers.set('Accept-version', environment.API_VERSION);
    const copiedReq = req.clone({ headers: additionalHeaders });

    if (req.url.includes('authentication')) {
      return next.handle(copiedReq);
    } else {
      const token = localStorage.getItem('jwt');
      if (!token) {
        return next.handle(copiedReq);
      }
      const tokenExpires = jwt_decode(token).exp;
      const currentTimeStamp = new Date().getTime() / 1000;
      if (tokenExpires - currentTimeStamp < 10) {
        return next.handle(copiedReq);
      } else {
        additionalHeaders.set('Authorization', localStorage.getItem('jwt'));

        return next.handle(copiedReq);
      }
    }
  }
}
