import { AfterViewInit, Component, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialInputComponent,
    multi: true,
  }],
})
export class MaterialInputComponent implements OnInit, ControlValueAccessor {

  @Input() autofocus: any;
  @Input() formControlName: string;
  @Input() formGroupName: string;
  @Input() type = 'text';
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() errors: any;
  @Input() hint: string;
  @Input() dummy: boolean;
  @Input() autocomplete = 'on';
  @Input() description;
  @Input() formControl: UntypedFormControl;
  @Input() readonly;
  @Input() maxlength;
  @Input() touchNeeded = true;
  @Input() styledDisabled = true;

  defaultId = Math.random();

  @ViewChild('inputEl', { static: true }) inputEl;
  @ViewChild('labelEl', { static: true }) labelEl;

  public focused: boolean;
  public onChange: any;
  public onTouch: any;
  public control: AbstractControl;
  public error: string;

  get counter() {
    return this.control.value ? this.control.value.length : '0';
  }

  get isInvalid() {
    if (this.touchNeeded) {
      return (this.control.dirty || this.control.touched) && this.control.invalid;
    } else {
      return this.control.invalid;
    }
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) { }

  writeValue(obj: any): void {
    // this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    if (this.formControl) {
      this.control = this.formControl;
    } else {
      if (this.controlContainer) {
        if (this.formControlName && !this.formGroupName) {
          this.control = this.controlContainer.control.get(this.formControlName);
        }
      } else {
        console.warn('Can\'t find parent FormGroup directive');
      }
    }

    this.control.valueChanges.subscribe((value) => {
      this.setError();
    });

    // also we need check errors on start
    this.setError();
  }

  focus($event) {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  blur($event) {
    this.focused = false;
  }

  public keydown(event) {
    if (this.dummy) {
      event.preventDefault();
      return false;
    }
  }

  private setError() {
    if (!this.control.errors) {
      this.error = '';
    } else if (this.errors) {
      this.error = this.errors[Object.keys(this.control.errors)[0]];
    }
  }

}
