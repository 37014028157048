export class Video {
  id: number;
  name: string;
  key: string;
  thumbnail_url: string;
  thumbnail_key: string;
  subscription_type: string; //TODO: add model ("FREE")
  status: string; //TODO: add model ("HIDDEN")
  duration: number;
  body_parts: [];
  video_playlists: [];
  updated_at: string;
  created_at: string;

  constructor( videoObject: Video ) {
    this.id = +videoObject.id;
    this.name = videoObject.name;
    this.key = videoObject.key;
    this.thumbnail_url = videoObject.thumbnail_url;
    this.thumbnail_key = videoObject.thumbnail_key;
    this.subscription_type = videoObject.subscription_type;
    this.status = videoObject.status;
    this.duration = +videoObject.duration;
    this.body_parts = videoObject.body_parts;
    this.video_playlists = videoObject.video_playlists;
    this.updated_at = videoObject.updated_at;
    this.created_at = videoObject.created_at;
  }
}
