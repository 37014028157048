import { TrainerLevel } from './trainer-level';

export class Duration {
  id?: number
  duration: number;
  price: number[];
  trainer_level: TrainerLevel
  constructor( durationObject: Duration ) {
    this.id = +durationObject.id;
    this.duration = +durationObject.duration;
    this.price = durationObject.price.map( el => +el );
    this.trainer_level = durationObject.trainer_level;
  }
}