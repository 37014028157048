import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PdfRendererService } from '../../../services/pdf-renderer.service';

@Component({
  selector: 'app-picture-modal',
  templateUrl: './picture-modal.component.html',
  styleUrls: ['./picture-modal.component.scss'],
})
export class PictureModalComponent implements OnInit, AfterViewInit {
  public isPdf = false;
  @ViewChild('canvasContainer', { static: true }) canvasContainer;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private pdf: PdfRendererService
  ) { }

  ngOnInit() {
    this.isPdf = this.pdf.isPdf(this.data);
  }

  ngAfterViewInit(): void {
    if (this.isPdf) {
      this.pdf.renderMedia(this.data, this.canvasContainer.nativeElement);
    }
  }
}
