import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { MatMenuOptionComponent } from '../mat-menu-option/mat-menu-option.component';

@Component({
  selector: 'app-mat-menu-options',
  templateUrl: './mat-menu-options.component.html',
  styleUrls: ['./mat-menu-options.component.scss'],
  animations: [
    trigger(
      'menu',
      [
        transition(
          ':enter', [
            style({
              transform: 'scale(0.3)',
              opacity: 0,
              maxHeight: '48px',
              transformOrigin: 'top right',
            }),
            animate('150ms', style({
              transform: 'scale(1)',
              opacity: 1,
              maxHeight: '50vh',
              transformOrigin: 'top right',
            })),
          ]
        ),
        transition(
          ':leave', [
            style({
              transform: 'scale(1)',
              opacity: 1,

            }),
            animate('125ms', style({
              opacity: 0,
              transform: 'scale(0.95)',
            })),
          ]
        )]
    ),

  ],
})
export class MatMenuOptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  public showMenu = false;
  @ContentChildren(MatMenuOptionComponent) options: MatMenuOptionComponent[];
  public clickEvent: EventEmitter<any>;
  private _subs: Subscription;

  constructor() {
    this.clickEvent = new EventEmitter();
  }

  ngOnInit() {
    this._subs = new Subscription();
  }

  ngAfterViewInit(): void {
    this.options.forEach(option => {
      this._subs.add(
        option.clickEvent.subscribe(clickedOption => {
          this.clickEvent.emit(clickedOption);
          this.showMenu = false;
        })
      );
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
