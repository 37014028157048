import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent {

  public menu = [
    'utv',
    'users',
    'company',
  ];

  constructor(
    translate: TranslateService,
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
