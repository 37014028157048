import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FeathersService } from '../../services/feathers.service';
import { DataService } from '../../services/data.service';
import { localStorage } from '../../utils/storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  admin: any;
  language: string;
  readonly actions = [
    {
      name: 'log_out',
      action: 'logout',
    },
  ];
  private readonly actionProvider = {
    logout: this.logout.bind(this),
  };

  get userImage() {
    const url = this.admin.profile_picture ? this.admin.profile_picture : 'src/assets/noimage.svg';
    return `url(${url})`;
  }

  constructor(
    private feathers: FeathersService,
    private router: Router,
    private data: DataService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.data.getAdmin().subscribe(res => {
      this.admin = res;
    }, error => {
      throw error;
    });

    this.language = this.translate.currentLang;
  }

  logout(): void {
    this.feathers.logout();
    localStorage.removeItem('mem-admin');
    this.router.navigate(['/']);
  }

  changeLang(lang) {
    this.translate.use(lang);
    this.language = lang;
    localStorage.setItem('lang', lang);
    location.reload();
  }

  menuClick(arg) {
    arg && this.actionProvider[arg]();
  }
}
