<div class="select-wrapper input">
  <div class="input-view" [ngClass]="{'focus': focused}" (click)="toggleFocus()">
    <span class="text">
      {{form.value.trainer_level?.level}} &#x200B;
    </span>
    <i appIcon="drop_down"></i>
  </div>
  <ul class="options" *ngIf="focused">
    <li *ngFor="let option of options; index as i" (click)="selectOption( $event, option )">
      {{option[fieldToShow]}}
    </li>
  </ul>
</div>