import { COMPANY_TABS } from '../utils/enum';

export class Company {
  id: number;
  name: string;
  logo: string;
  country: string;
  address: string;
  email: string;
  enabled: false;
  enabled_at: string;
  disabled_at?: string;
  accepted_employees_count: number;
  trial_period_expires_at: string;
  status_changed_at?: string;

  constructor(company) {
    this.id = company.id;
    this.name = company.name;
    this.logo = company.logo;
    this.country = company.country;
    this.address = company.address;
    this.email = company.email;
    this.enabled = company.enabled;
    this.enabled_at = company.enabled_at;
    this.disabled_at = company.disabled_at;
    this.accepted_employees_count = company.accepted_employees_count;
    this.trial_period_expires_at = company.trial_period_expires_at;
    this.status_changed_at = company.status_changed_at;
  }

  get status(): COMPANY_TABS {
    if (new Date() < new Date(this.trial_period_expires_at)) {
      return COMPANY_TABS.TRIAL;
    }
    return this.enabled ? COMPANY_TABS.ENABLED : COMPANY_TABS.DISABLED;
  }
}

export class CompanyDetails {
  original_status: COMPANY_TABS = null;
  id: number = null;
  name: string = null;
  logo: string = null;
  email: string = null;
  description: string = null;
  country: string = null;
  address: string = null;
  accepted_employees_count: number = null;
  pending_employees_count: number = null;
  pai_share_employees_count: number = null;
  trial_period_expires_at: string = null;
  enabled: boolean = null;
  enabled_at: string = null;
  disabled_at: string = null;
  status: string = null;
  last_action_at: string = null;
  statistic: {
    week: CompanyStatistic;
    month: CompanyStatistic;
  } = {
      week: null,
      month: null,
    };

  constructor(companyDetails) {
    if (companyDetails) {
      for (const key of Object.keys(companyDetails)) {
        if (key === 'statistic') {
          this[key].week = new CompanyStatistic(companyDetails[key].week);
          this[key].month = new CompanyStatistic(companyDetails[key].month);
        } else if (Object.prototype.hasOwnProperty.call(this, key)) {
          this[key] = companyDetails[key];
        }
      }
    }
  }
}

export class CompanyStatistic {
  open_app_count: number;
  pai_sync_count: number;

  constructor(statistic) {
    this.open_app_count = statistic.open_app_count;
    this.pai_sync_count = statistic.pai_sync_count;
  }
}
