import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Company, CompanyDetails, CompanyStatistic } from '@models/company';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyService } from '@services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@components/modals/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMPANY_TABS } from '@directives/enum';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {

  detailsFG: FormGroup;
  companyDetails: CompanyDetails;

  weekStatistic: CompanyStatistic;
  monthStatistic: CompanyStatistic;

  weekEndDate: Date = new Date();
  weekStartDate: Date = moment().startOf('isoWeek').toDate();

  monthEndDate: Date = new Date();
  monthStartDate: Date = this.getStartOfMonthDate(this.monthEndDate);

  minDate: Date = new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1);
  maxDate: Date = new Date();

  COMPANY_TABS = COMPANY_TABS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public company: Company,
    private dialogRef: MatDialogRef<CompanyDetailsComponent>,
    private companyService: CompanyService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  get isDisableMonthBack(): boolean {
    return new Date(new Date(this.minDate).setMonth(this.minDate.getMonth() + 1)).getTime() > this.monthStartDate.getTime();
  }

  get isDisableMonthForward(): boolean {
    return this.maxDate.getTime() <= this.monthEndDate.getTime();
  }

  get isDisableWeekBack(): boolean {
    return this.minDate.getTime() >= this.weekStartDate.getTime();
  }

  get isDisableWeekForward(): boolean {
    return this.maxDate.getTime() <= this.weekEndDate.getTime();
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  get companyStatus(): string {
    if (this.company.status === COMPANY_TABS.TRIAL) {
      return this.translate.instant('company.trialStatus');
    }

    return this.company.status;
  }

  ngOnInit(): void {
    this.getCompanyDetails(this.company.id);
    this.getStatisticByWeek(this.company.id, this.weekStartDate, this.weekEndDate);
    this.getStatisticByMonth(this.company.id, this.monthStartDate, this.monthEndDate);
  }

  public close(changes = false): void {
    this.dialogRef.close(changes);
  }

  public switchWeekForward(): void {
    this.weekStartDate = this.getNextStartWeekDate();
    this.weekEndDate = this.getNextWeekEndDate();

    this.getStatisticByWeek(this.company.id, this.weekStartDate, this.weekEndDate);
  }

  public switchWeekBack(): void {
    this.weekStartDate = this.getPreviousStartWeekDate();
    this.weekEndDate = this.getPreviousWeekEndDate();

    this.getStatisticByWeek(this.company.id, this.weekStartDate, this.weekEndDate);
  }

  public switchMonthForward(): void {
    const startDate = new Date(this.monthStartDate);
    const endDate = new Date(this.monthEndDate);

    this.monthStartDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    this.monthEndDate = new Date(endDate.getFullYear(), endDate.getMonth() + 2, 0);

    this.getStatisticByMonth(this.company.id, this.monthStartDate, this.monthEndDate);
  }

  public switchMonthBack(): void {
    const startDate = new Date(this.monthStartDate);
    const endDate = new Date(this.monthEndDate);

    this.monthStartDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    this.monthEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);

    this.getStatisticByMonth(this.company.id, this.monthStartDate, this.monthEndDate);
  }

  public openEnableModal(company: Company): void {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: company.id,
        headerTxt: this.translate.instant('company.enableCompany'),
        bodyTxt: this.translate.instant('company.enableCompanyConfirmation'),
        actionPrimary: this.translate.instant('company.yesEnable'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result) => {
        if (result) {
          this.changeCompanyStatus(this.company, true);
        }
      });
  }

  public openDisableModal(company: Company): void {
    event.stopPropagation();
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: company.id,
        headerTxt: this.translate.instant('company.disableCompany'),
        bodyTxt: this.translate.instant('company.disableCompanyConfirmation'),
        actionPrimary: this.translate.instant('company.yesDisable'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result) => {
        if (result) {
          this.changeCompanyStatus(this.company, false);
        }
      });
  }

  public openDeleteModal(company: Company): void {
    event.stopPropagation();
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: company.id,
        headerTxt: this.translate.instant('company.deleteCompany'),
        bodyTxt: this.translate.instant('company.deleteCompanyConfirmation'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result) => {
        if (result) {
          this.deleteCompany(this.company);
        }
      });
  }

  private getNextWeekEndDate(): Date {
    const updatedDate = new Date(this.weekStartDate.getFullYear(), this.weekStartDate.getMonth(), this.weekStartDate.getDate() + 6);

    return updatedDate > new Date() ? new Date() : updatedDate;
  }

  private getPreviousWeekEndDate(): Date {
    return new Date(this.weekStartDate.getFullYear(), this.weekStartDate.getMonth(), this.weekStartDate.getDate() + 6);
  }

  private getNextStartWeekDate(): Date {
    const updatedDate = new Date(this.weekStartDate.getFullYear(), this.weekStartDate.getMonth(), this.weekStartDate.getDate() + 7);

    return updatedDate > new Date() ? this.weekStartDate : updatedDate;
  }

  private getPreviousStartWeekDate(): Date {
    const updatedDate = new Date(this.weekStartDate.getFullYear(), this.weekStartDate.getMonth(), this.weekStartDate.getDate() - 7);

    return updatedDate < moment().subtract(2, 'years').toDate() ? this.weekStartDate : updatedDate;
  }

  private initForm(): void {
    this.detailsFG = new FormGroup({
      companyId: new FormControl<number>({value: this.companyDetails.id, disabled: true}),
      companyName: new FormControl<string>({value: this.companyDetails.name, disabled: true}),
      email: new FormControl<string>({value: this.companyDetails.email, disabled: true}),
      country: new FormControl<string>({value: this.companyDetails.country, disabled: true}),
      address: new FormControl<string>({value: this.companyDetails.address, disabled: true}),
      description: new FormControl<string>({value: this.companyDetails.description, disabled: true}),
    });
  }

  private getCompanyDetails(id: number): void {
    this.companyService.getCompanyDetails(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(details => {
        this.companyDetails = details;
        this.initForm();
      });
  }

  private getStatisticByWeek(id: number, startDate: Date, endDate: Date): void {
    this.companyService.getCompanyStatistic(id, startDate, endDate)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(statistic => {
        this.weekStatistic = statistic.week;
      });
  }

  private getStatisticByMonth(id: number, startDate: Date, endDate: Date): void {
    this.companyService.getCompanyStatistic(id, startDate, endDate)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(statistic => {
        this.monthStatistic = statistic.month;
      });
  }

  private changeCompanyStatus(company: Company, enable: boolean): void {
    this.companyService.changeStatus(company.id, enable)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (res) {
          if (enable) {
            this.showSuccessMessage(this.translate.instant('company.successEnabled'));
          } else {
            this.showSuccessMessage(this.translate.instant('company.successDisabled'));
          }
          this.close(true);
        }
      });
  }

  private deleteCompany(company: Company): void {
    this.companyService.deleteCompany(company.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (res) {
          this.showSuccessMessage(this.translate.instant('company.successDeleting'));
          this.close(true);
        }
      });
  }

  private getLastWeeksDate(endDate: Date): Date {
    const date = new Date(endDate);

    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
  }

  private getStartOfMonthDate(endDate: Date): Date {
    const date = new Date(endDate);

    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(
      message,
      '',
      {
        duration: 2500,
        panelClass: 'success',
        verticalPosition: 'top',
      },
    );
  }

}

