<div class="modal-header">
    <span *ngIf="!isEdit">
      {{'pricing.add_level' | translate}}
    </span>
  <span *ngIf="isEdit">
      {{'pricing.edit_level' | translate}}
    </span>
  <button class="cancel" appIcon="cancel" mat-dialog-close></button>
</div>
<div class="modal-body">
  <form [formGroup]="levelForm">
    <div class="input">
      <label for="">{{'pricing.level' | translate}}</label>
      <input cdkFocusInitial
             type="text"
             formControlName="level"
             [ngClass]="{'invalid': levelForm.controls.level.invalid && levelForm.controls.level.dirty}"
             placeholder="{{'pricing.level' | translate}}">
      <app-errors [form]="levelForm" field="level"></app-errors>
    </div>
    <div class="input">
      <label>{{'pricing.salary' | translate}}</label>
      <input type="number"
             appNumberInput
             placeholder="{{'pricing.salary' | translate}}"
             [ngClass]="{'invalid': levelForm.controls.trainer_percent.invalid && levelForm.controls.trainer_percent.dirty}"
             formControlName="trainer_percent">
      <app-errors [form]="levelForm" field="trainer_percent"></app-errors>
    </div>
  </form>
  <div class="actions" *ngIf="!attention">
    <button class="grey" *ngIf="isEdit" (click)="attention = true">
      {{'common.delete' | translate}}
    </button>
    <button class="accent" (click)="save()" [disabled]="levelForm.invalid">
      {{'common.save' | translate}}
    </button>
  </div>
  <div class="actions attention" *ngIf="attention">
    <div class="text">
        <span class="attention-title">
          {{'pricing.delete_level' | translate}}
        </span>
      <span class="">
          {{'pricing.you_wont_be_able_to_undo' | translate}}
        </span>
    </div>
    <button (click)="attention = false">{{'common.no' | translate}}</button>
    <button (click)="remove()">{{'common.yes' | translate}}</button>
  </div>
</div>
