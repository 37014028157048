<label 
  class="material_label"
  [ngClass]="{
    'material_label--focused': focused,
    'material_label--dirty': control.value,
    'material_label--disabled': control.disabled && styledDisabled,
    'material_label--invalid': isInvalid
  }">
  {{label}}
</label>

<textarea
  class="material-textarea"
  [maxlength]="maxlength"
  [readonly]="readonly"
  (focus)="focus($event)"
  (blur)="blur($event)"
  [ngClass]="{
    'material-textarea--focused': focused,
    'material-textarea--dirty': control.value,
    'material-textarea--disabled': control.disabled && styledDisabled,
    'material-textarea--invalid': isInvalid
  }"
  [formControl]="control">
</textarea>

<div *ngIf="maxlength" class="textarea-counter">
  {{counter}} / {{maxlength}}
</div>