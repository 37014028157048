import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FeathersService} from './feathers.service';

@Injectable()
export class GuardInterceptor implements HttpInterceptor {

  constructor(
    private feathers: FeathersService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
         return;
        }
        this.feathers.logout();
        localStorage.removeItem('mem-admin');
        this.router.navigate(['/']);
      }
    }));
  }
}
