const KStorageType = {
  LOCAL: 0,
  SESSION: 1,
};

/**
 * Browser's LocalStorage or SessionStorage wrapper to make fallback if no browser Storage available
 */
class KStorage {
  getItem: KStorage['get'];
  setItem: KStorage['set'];
  removeItem: KStorage['remove'];
  window;

  private _storageAvailable;
  private _storage;

  constructor(type) {
    if (type !== KStorageType.LOCAL && type !== KStorageType.SESSION) {
      throw new Error('Invalid KStorage type provided');
    }
    this._storageAvailable = false;

    if (typeof window !== 'undefined') {
      this.window = window;
    } else {
      this.window = {};
    }

    if (type === KStorageType.LOCAL) {
      this._storage = this.window.localStorage;
    } else if (type === KStorageType.SESSION) {
      this._storage = this.window.sessionStorage;
    } else {
      this._storage = null;
    }

    if (this._storage) {
      try {
        this._storage.setItem('KLS_test_entry', 'KLS_test_data');
        this._storage.removeItem('KLS_test_entry');
        this._storageAvailable = true;
      } catch (err) {
        this.fallbackToMemoryStorage();
      }
    } else {
      this.fallbackToMemoryStorage();
    }

    /**
     * Aliases for core functions
     */
    this.getItem = this.get.bind(this);
    this.setItem = this.set.bind(this);
    this.removeItem = this.remove.bind(this);
  }

  /**
   * Performs fallback to MemoryStorage
   * @returns {boolean}
   */
  fallbackToMemoryStorage(): boolean {
    console.warn('Browser storage isn\'t available. Fallback to JS memory store');
    this._storageAvailable = true;
    this._storage = new KMemoryStorage();
    return true;
  }

  /**
   * Wrapper method for setItem method of native browser Storage
   * @param key
   * @param value
   */
  set(key, value): boolean {
    if (!this._storageAvailable) {
      return false;
    }

    if (!key) {
      console.warn('Provide valid key to set storage item');
      return false;
    }

    if (!value) {
      console.warn('Provide valid value to set storage item');
      return false;
    }

    try {
      this._storage.setItem(key, value);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Wrapper method for getItem method of native browser Storage
   * @param key
   */
  get(key): any {
    if (!this._storageAvailable) {
      return false;
    }

    if (!key) {
      console.warn('Provide a valid key to get storage item');
      return false;
    }

    try {
      return this._storage.getItem(key) || null;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Wrapper method for removeItem method of native browser Storage
   * @param key
   */
  remove(key): boolean {
    if (!this._storageAvailable) {
      return false;
    }

    if (!key) {
      console.warn('Provide valid key to get storage item');
      return false;
    }

    try {
      this._storage.removeItem(key);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

/**
 * Memory kay/value storage. Data will be stored until page is open.
 * This storage couldn't store data permanently!!!
 */
class KMemoryStorage {
  private readonly _storage;

  constructor() {
    this._storage = {};
  }

  /**
   * Write serialized data to storage
   * @param key
   * @param value
   * @returns {boolean}
   */
  setItem(key, value): any {
    if (!key || !value) {
      return false;
    }
    this._storage[key] = value;
    return true;
  }

  /**
   * Get serialized data from the storage
   * @param key
   * @returns {*}
   */
  getItem(key): any {
    if (!key) {
      return false;
    }
    return this._storage[key];
  }

  /**
   * Remove data from storage
   * @param key
   * @returns {boolean}
   */
  removeItem(key): any {
    if (!key) {
      return false;
    }
    delete this._storage[key];
    return true;
  }
}

export const localStorage = new KStorage(KStorageType.LOCAL);
export const sessionStorage = new KStorage(KStorageType.SESSION);
