<div class="buttonRow">
</div>

<div class="video-page__container">

  <header class="page-card-header page-card-header--flex">
    <div class="page-card-header-tabs">
      <div class="page-card-header-tabs-tab"
        (click)="changeTab('ALL')"
        [ngClass]="{ 'page-card-header-tabs-tab--active': checkTab('ALL') }">
        {{'utv.all' | translate}}
      </div>
      <div class="page-card-header-tabs-tab page-card-header-tabs-tab"
        (click)="changeTab('PLAYLISTS')"
        [ngClass]="{ 'page-card-header-tabs-tab--active': checkTab('PLAYLISTS') }">
        {{'utv.playlists' | translate}}
      </div>
      <div class="page-card-header-tabs-tab page-card-header-tabs-tab"
        (click)="changeTab('CATEGORIES')"
        [ngClass]="{ 'page-card-header-tabs-tab--active': checkTab('CATEGORIES') }">
        {{'utv.categories' | translate}}
      </div>
    </div>

    <div class="page-card-header-actions">
      <button class="add-button"
              routerLink="/utv/category/create">
        <i class="icon-memento-plus"></i>
        <span>{{'utv.add_category' | translate}}</span>
      </button>

      <button class="add-button"
              routerLink="/utv/playlist/create">
        <i class="icon-memento-plus"></i>
        <span>{{'utv.create_playlist' | translate}}</span>
      </button>

      <button
        (click)="fileInput.click()"
        class="upload-button">
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 17H14V15H0V17ZM0 7H4V13H10V7H14L7 0L0 7Z" fill="white"/>
        </svg>
        <span>{{'utv.upload_video' | translate}}</span>
      </button>

      <input
        #fileInput
        multiple
        (change)="uploadFiles($event)"
        accept="video/mp4, video/quicktime"
        class="hidden_input"
        type="file">
    </div>
  </header>

  <div *ngIf="checkTab('ALL')" class="video-page__table">
    <div appTableHeader="grid" class="table-header">
      <div class="header-column">
        <span>{{'utv.table.video' | translate}}</span>
      </div>
      <div class="header-column">
        <span>{{'utv.table.name' | translate}}</span>
      </div>
      <div class="header-column">
        <span>{{'utv.table.subscription' | translate}}</span>
      </div>
      <div class="header-column">
        <span>{{'utv.table.body_parts' | translate}}</span>
      </div>
      <div class="header-column">
        <span>{{'utv.table.status' | translate}}</span>
      </div>
      <div class="header-column">
        <span>{{'utv.table.duration' | translate}}</span>
      </div>
      <div class="header-column">
      </div>
    </div>

    <div *ngIf="videosExist; else emptyList" class="video-list" (scroll)="videosOnScroll($event)">
      <div #videoList>
        <app-video-list-item
          *ngFor="let video of videos; let index = index; trackBy: trackByFn"
          [video]="video"
          [index]="index"
          (remove)="removeVideo($event)"
          (cancel)="cancelFilesUploading($event)">
        </app-video-list-item>
      </div>
    </div>

    <ng-template #emptyList>
      <div class="video-list__empty">
        <img alt="empty list" class="image-empty" src="assets/logo_grey.svg">
        <div class="text-empty">{{'utv.no_video_yet' | translate}}</div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="checkTab('PLAYLISTS')" class="video-page__table">
    <app-video-playlists></app-video-playlists>
  </div>

  <div *ngIf="checkTab('CATEGORIES')" class="video-page__table">
    <app-video-categories></app-video-categories>
  </div>

</div>

<app-upload-files-modal
  *ngIf="isLoadingModalOpen"
  [uploadProgressInfos] = "uploadProgressInfos"
  (cancelAll)="cancelFilesUploading($event)"
  (cancelCurrentDownload)="cancelCurrentDownload($event)"
  (cancelScheduledDownload)="cancelScheduledDownload($event)">
</app-upload-files-modal>

<router-outlet></router-outlet>
