import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent implements OnInit {
  @Input()
    placeholder: string = '';

  @Input()
    debounce: number = 250;

  @Input()
    clearable: boolean = false;

  @Output()
    searchClicked = new EventEmitter<string>();

  @Output()
    clearClicked = new EventEmitter();

  @Output()
    searchTyped = new EventEmitter<string>();

  readonly searchFC: FormControl<string> = new FormControl();

  ngOnInit(): void {
    this.listenForm();
  }

  search(): void {
    const data = this.searchFC.value || '';
    this.searchClicked.emit(data);
  }

  reset(): void {
    this.searchFC.reset();
  }

  clear(): void {
    this.reset();
    this.clearClicked.emit();
  }

  private listenForm(): void {
    this.searchFC.valueChanges
      .pipe(
        this.debounce ? debounceTime(this.debounce) : null,
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.searchTyped.emit(value);
      });
  }

}

