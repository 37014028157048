import { environment } from '../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppSettings {
  public static googleApiKey = 'AIzaSyC37KwZW8BnzjOtk9Lnf441kfDB_-Wy9Ks';
  public API_server: string;
  private host: string = location.host;

  constructor() {
    this.API_server = this.setApiServer();
  }

  private setApiServer() {
    return environment.API;
  }
}
