import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { UPLOAD_LIMITS } from '@directives/enum';
import { Category } from '@models/category';
import { UtvService } from '@services/utv.service';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-video-category-edit',
  templateUrl: './video-category-edit.component.html',
  styleUrls: ['./video-category-edit.component.scss'],
})
export class VideoCategoryEditComponent implements OnInit {
  public category: Category;
  public categoryDataFormGroup: UntypedFormGroup;
  public allMediaElements: [] = [];
  public categoryMediaElements: any[];
  public imgURL: any;
  public isNewCategory: boolean;

  private imgFile: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private utvService: UtvService,
    private translate: TranslateService,
  ) { }

  get name() { return this.categoryDataFormGroup.get('name'); }
  get image() { return this.categoryDataFormGroup.get('image'); }
  get attached() { return this.categoryDataFormGroup.get('attached').value; }

  ngOnInit() {
    this.initCategoryDataForm();
    this.categoryElementValueChanges();
    this.getCategoriesElements();
    this.isNewCategory = this.route.snapshot.params.id === undefined ? true : false;
    if (!this.isNewCategory) {
      this.getCategory();
      this.utvService.getCategory(this.route.snapshot.params.id);
    } else {
      this.categoryInit();
    }
  }

  getCategory() {
    this.utvService.category
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result: any) => {
        this.category = result;
        this.categoryInit();
      });
  }

  transformMediaElementList(categoriesMediaElements) {
    let result = [];
    if (!categoriesMediaElements) {
      return result;
    }
    // adding '_v' and '_p' is necessary for the app-material-select to work properly,
    // we make the ID unique, since the ID of the videos and playlists can be the same
    if (categoriesMediaElements[0]) {
      result = categoriesMediaElements[0].map(video => {
        return {
          type: 'video',
          typeName: this.translate.instant('utv.video'),
          id: video.id + '_v',
          name: video.name,
          thumbnail_url: video.thumbnail_url,
        };
      });
    }
    if (categoriesMediaElements[1]) {
      result = result.concat(
        categoriesMediaElements[1].map(playlist => {
          return {
            type: 'playlist',
            typeName: this.translate.instant('utv.playlist'),
            id: playlist.id + '_p',
            name: playlist.name,
            thumbnail_url: playlist.image,
          };
        })
      );
    }
    return result;
  }

  getCategoriesElements() {
    this.utvService.getPublishedCategoriesElements()
      .pipe(
        map((result: any) => {
          return this.transformMediaElementList([result[0].data, result[1].data]);
        }),
        untilDestroyed(this),
      )
      .subscribe((result: any) => {
        this.allMediaElements = result;
      });
  }

  initCategoryDataForm() {
    this.categoryDataFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
      ]),
      image: new UntypedFormControl('', [
        Validators.required,
      ]),
      attached: new UntypedFormControl(''),
    });
  }

  categoryInit() {
    if (this.category && this.category.id) {
      this.imgURL = this.category.image;
      this.categoryMediaElements = this.transformMediaElementList([this.category.attached.videos, this.category.attached.video_playlists]);
      this.categoryDataFormGroup.patchValue({
        name: this.category.name,
        image: this.category.image,
        attached: this.transformMediaElementList([this.category.attached.videos, this.category.attached.video_playlists]),
      });
    } else {
      this.imgURL = '';
      this.categoryMediaElements = [];
      this.categoryDataFormGroup.patchValue({
        name: '',
        image: '',
        attached: [],
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categoryMediaElements, event.previousIndex, event.currentIndex);
  }

  categoryElementValueChanges() {
    this.categoryDataFormGroup.get('attached').valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.updateSortedMediaElementList(value);
      }, error => {
        throw error;
      });
  }

  updateSortedMediaElementList(mediaElementList) {
    if (mediaElementList.length > this.categoryMediaElements.length) {
      // adding Media Element to category by using search
      const onlyInMediaElementList = mediaElementList.filter(this.comparer(this.categoryMediaElements));
      this.categoryMediaElements = onlyInMediaElementList.concat(this.categoryMediaElements);
    } else {
      // deleting Media Element from category by using search
      this.categoryMediaElements = this.categoryMediaElements.filter(this.comparer(mediaElementList, false));
    }
  }

  handleError(error: Error) {
    this.utvService.showNotification(error.message, null, 3000, 'error');
  }

  comparer(otherArray, isAdding = true) {
    return (currentObj) => {
      const result = otherArray.filter(otherObj => {
        return otherObj.id === currentObj.id;
      }).length === 0;
      return isAdding ? result : !result;
    };
  }

  deleteMediaElementFromCategory(id: number, elementId: string) {
    this.categoryMediaElements.splice(id, 1);
    const remainingMediaElements = this.categoryMediaElements.filter(element => {
      return element.id !== elementId;
    });

    this.categoryDataFormGroup.patchValue({
      attached: remainingMediaElements,
    });
  }

  saveCategory() {
    this.categoryDataFormGroup.markAllAsTouched();
    if (this.categoryDataFormGroup.invalid) {
      return;
    }

    if (this.imgFile) {
      this.uploadCategoryImage();
    } else {
      this.saveCategoryData();
    }
  }

  uploadCategoryImage() {
    this.utvService.uploadImage('category', this.imgFile)
      .subscribe(response => {
        this.categoryDataFormGroup.patchValue({
          image: response.url,
        });
        this.saveCategoryData();
      }, (error) => {
        throw error;
      });
  }

  saveCategoryData() {
    const categoryData: any = {};
    Object.keys(this.categoryDataFormGroup.controls).forEach(key => {
      if (key === 'attached') {
        categoryData['attached'] = { video_ids: [], video_playlist_ids: [] };
        this.categoryMediaElements.forEach(item => {
          if (item.type === 'video') {
            categoryData.attached.video_ids.push(+item.id.replace('_v', ''));
          } else if (item.type === 'playlist') {
            categoryData.attached.video_playlist_ids.push(+item.id.replace('_p', ''));
          }
        });
      } else {
        categoryData[key] = this.categoryDataFormGroup.controls[key].value;
      }
    });

    (this.category ? this.utvService.editCategory(this.category.id, categoryData) : this.utvService.createCategory(categoryData))
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: (resultCategory) => {
          if(this.category) {
            this.utvService.changeCategory(resultCategory);
            this.utvService.showNotification(this.translate.instant('utv.category_edited'), null, 3000, 'success');
          } else {
            this.utvService.addCategory(resultCategory);
            this.utvService.showNotification(this.translate.instant('utv.category_edited'), null, 3000, 'success');
          }

          this.closeEditCategoryDialog();
        },
        error: () => {
          this.closeEditCategoryDialog();
        },
      });
  }

  previewThumbnailImage(files) {
    if (files.length === 0) {
      return;
    }
    this.imgFile = files[0];

    const mimeType = this.imgFile.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    if (this.imgFile.size > UPLOAD_LIMITS.IMG_MAX_SIZE) {
      this.utvService.showNotification(
        this.translate.instant('utv.exceedingFileSize', {
          name: this.imgFile.name,
          size: UPLOAD_LIMITS.IMG_MAX_SIZE_STR,
        }), null, 3000, 'error');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
      this.categoryDataFormGroup.patchValue({
        image: reader.result,
      });
    };
  }

  confirmDeleteCategoryDialog() {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: this.category.id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_category'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(
        filter((result) => !!result?.id),
        switchMap((result) => this.utvService.removeCategory(result.id)),
        untilDestroyed(this),
      )
      .subscribe(category => {
        this.utvService.removeCategoryById(category.id);
        this.utvService.showNotification(this.translate.instant('utv.category_removed'), null, 3000, 'success');
        this.closeEditCategoryDialog();
      });
  }

  closeEditCategoryDialog() {
    this.router.navigate(['/utv']);
  }
}
