import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  readonly quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['clean'],
      ['link'],
    ],
  };
  readonly emptyQuillConfig = {
    toolbar: [],
  };
  public tabType = '';
  public data: any = {};

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.tabType = this.route.snapshot.params.type;
    this.contentService.getSingleDataPiece(this.route.snapshot.params.id)
      .subscribe((response) => {
        this.data = Object.assign({}, response);
      }, error => {
        throw error;
      });
  }

  save() {
    this.contentService.save(this.data, this.route.snapshot.params.id).subscribe(response => {
      this.snackBar.open('Saved successfully!', '', {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['action-completed', 'action-completed-success'],
      });
      this.router.navigate(['/content', this.route.snapshot.params.type]);
    }, error => {
      this.snackBar.open('An error occured. Data wasn\'t saved', '', {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['action-completed', 'action-completed-fail'],
      });
      this.router.navigate(['/content', this.route.snapshot.params.type]);
      throw error;
    });
  }
}
