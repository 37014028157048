<div class="modal-wrapper video-modal">
  <div class="overlay" routerLink="../"></div>
  <div class="card" *ngIf="video">
    <div class="card-header">
      <div class="title">
        {{'utv.edit_video' | translate}} 
      </div>
      <div class="close" routerLink="../"></div>
    </div>

    <div class="modal-body">
      <form [formGroup]="videoDataFormGroup"
            class="modal-body-form">
        <section class="modal-body-section">

          <div class="input">
            <app-material-input formControlName="name"
                                class="material-input"
                                [label]="'utv.video_name' | translate"
                                [maxlength]="50">
            </app-material-input>
            <div class="hint" [ngClass]="{ 'alert': name.invalid && (name.dirty || name.touched) }">
              *{{'utv.write_video_name' | translate}}
            </div>
          </div>

          <div class="input input-select">
            <app-material-select formControlName="subscription_type"
                                 class="material-select"
                                 [options]="subscriptionTypes"
                                 valueFieldName="value"
                                 labelFieldName="title"
                                 [label]="'utv.video_subscription_type' | translate">
            </app-material-select>
            <div class="hint">
              {{'utv.select_subscription' | translate}}
            </div>
          </div>

          <div class="input input-select">
            <app-material-select formControlName="body_parts"
                                 class="material-select"
                                 [options]="bodyParts"
                                 [multiSelect]="true"
                                 [showSelectAll]="false"
                                 [showValue]="'name'"
                                 [search]="true"
                                 [label]="'utv.body_parts' | translate"
                                 [errors]="{required: 'validation.fieldRequired' | translate}">  
            </app-material-select>
            <div class="hint">
              {{'utv.select_body_part' | translate}}
            </div>
            <div class="list">
              <app-chips *ngFor="let bodyPart of videoDataFormGroup.get('body_parts').value; index as i"
                         class="multi-select-chips"
                         [value]="bodyPart.name"
                         [removable]="true"
                         (remove)="removeItem(i, 'body_parts')">
              </app-chips>
            </div>
          </div>

          <div class="input input-select capitalize">
            <app-material-select formControlName="status"
                                 class="material-select"
                                 [options]="statuses"
                                 valueFieldName="value"
                                 labelFieldName="title"
                                 [label]="'utv.status' | translate">
            </app-material-select>
            <div class="hint">
              {{'utv.select_svideo_status' | translate}}
            </div>
            <div class="info-item">
              <div class="info-item-text">
                {{'utv.change_status_tip' | translate}}
              </div>
            </div>
          </div>  

          <div class="input input-select">
            <app-material-select formControlName="playlists"
                                 class="material-select"
                                 [options]="playlists"
                                 [multiSelect]="true"
                                 [showSelectAll]="false"
                                 [showValue]="'name'"
                                 [search]="true"
                                 [label]="'utv.playlists' | translate"
                                 [errors]="{required: 'validation.fieldRequired' | translate}">  
            </app-material-select>
            <div class="hint">
              {{'utv.select_video_playlist' | translate}}
            </div>
            <div class="list">
              <app-chips *ngFor="let playlist of videoDataFormGroup.get('playlists').value; index as i"
                         class="multi-select-chips"
                         [value]="playlist.name"
                         [removable]="true"
                         (remove)="removeItem(i, 'playlists')">
              </app-chips>
            </div>
          </div>
        </section>

        <section class="modal-body-section">
          <div class="input thumbnail-input">
            <div class="image-empty-container">
              <img alt="empty list" class="image-empty" src="assets/logo_grey.svg">
            </div>
            <img class="thumbnail-image" [src]="imgURL" *ngIf="imgURL">
            <input #imageFileInput
                    type="file"
                    accept="image/png, image/jpeg"
                    class="hidden_input"
                    (change)="previewThumbnailImage(imageFileInput.files)" />
            <button
              (click)="imageFileInput.click()"
              class="upload-thumbnail-button">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3488 5.16278H6.83719V0.651164C6.83719 0.445641 6.55811 0 5.99998 0C5.44184 0 5.16276 0.445665 5.16276 0.651164V5.16281H0.651161C0.445663 5.16278 0 5.44186 0 5.99998C0 6.55809 0.445663 6.83719 0.651161 6.83719H5.16279V11.3488C5.16279 11.5543 5.44184 12 6 12C6.55816 12 6.83721 11.5543 6.83721 11.3488V6.83719H11.3488C11.5543 6.83719 12 6.55814 12 5.99998C12 5.44181 11.5543 5.16278 11.3488 5.16278Z" fill="#14BFFB"/>
              </svg>
              <span>{{'utv.thumbnail_picture' | translate}}</span>
            </button>
          </div>
        </section>
      </form>
    </div>

    <div class="action">
      <div class="action-left-side">
        <button class="button" (click)="confirmDeleteVideoDialog()">
          {{'utv.delete_video' | translate}}
        </button>
      </div>
      <div class="action-right-side">
        <button class="button" routerLink="../">
          {{'common.cancel' | translate}}
        </button>
        <button class="button accent" (click)="saveVideo()">
          {{'common.save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
