import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {

  /**
   *
   * @param array array to be filtered
   * @param search search parameter
   * @param type 'name', 'full_name', 'zone'
   */
  transform(array: any[], search?: string, type?: string): any {
    let arr;

    if (!array) { return; }

    switch (type) {
      case 'zone':
        arr = [...array.filter(item => this.filterByZones(item, search))];
        break;
      case 'full_name':
        arr = [...array.filter(item => this.filterByFullName(item, search))];
        break;
      case 'name':
        arr = [...array.filter(item => this.filterByName(item, search))];
        break;
      default:
        arr = array;
    }
    return arr;
  }

  filterByName(item, search) {
    const regexp = new RegExp(search, 'gi');
    item.first_name = this.sanitize(item.first_name);
    item.last_name = this.sanitize(item.last_name);
    const fullName = `${item.first_name} ${item.last_name}`;
    return fullName.search(regexp) > -1;
  }

  filterByZones(item, search) {
    const regexp = new RegExp(search, 'gi');
    item.city = this.sanitize(item.city);
    item.region = this.sanitize(item.region);
    return item.city.search(regexp) > -1 || item.region.search(regexp) > -1;
  }

  filterByFullName(item, search) {
    const regexp = new RegExp(search, 'gi');
    item.full_name = this.sanitize(item.full_name);
    return item.full_name.search(regexp) > -1;
  }

  /**
   * Apparently we cannot rely on server data consistency, so there is a need of sanitizing filtering values in case of nulls and undefined
   * @param value any primitive value (and null)
   */
  sanitize(value: null | undefined | string | number) {
    return !value ? '' : value;
  }
}

