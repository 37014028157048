import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, CompanyDetails, CompanyStatistic } from '../models/company';
import { HttpListResponse } from '../models/http-list-response';
import { UrlParams } from '../models/url-params';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  constructor(
    private http: HttpService,
    private datePipe: DatePipe,
  ) { }

  getCompanies(params: UrlParams): Observable<HttpListResponse<Company>> {
    return this.http.getData<HttpListResponse<Company>>('/admin/companies', params)
      .pipe(
        map((res) => {
          res.data = res.data.map((company) => {
            return new Company(company);
          });
          return res;
        }),
      );
  }

  getCompanyDetails(id: number): Observable<CompanyDetails> {
    return this.http.getData<CompanyDetails>(`/admin/companies/${id}`)
      .pipe(
        map((res) => {
          return new CompanyDetails(res);
        }),
      );
  }

  /**
   * Get company statistic
   * @param id
   * @param startDate
   * @param endDate
   * @returns Observable<CompanyStatistic>
   */
  getCompanyStatistic(
    id: number,
    startDate: Date,
    endDate: Date,
  ): Observable<{week: CompanyStatistic; month: CompanyStatistic}> {
    const start = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const end = this.datePipe.transform(endDate, 'yyyy-MM-dd');

    return this.http.getData<{week: CompanyStatistic; month: CompanyStatistic}>(
      `/admin/companies/${id}/statistics?startDate=${start}&endDate=${end}`,
    )
      .pipe(
        map((res) => {
          return {
            week: new CompanyStatistic(res.week),
            month: new CompanyStatistic(res.month),
          };
        }),
      );
  }

  /**
   * Enable/disable company
   * @param id
   * @param enabled
   */
  changeStatus(id: number, enabled: boolean): Observable<Company> {
    return this.http.patchData<Company>(`/admin/companies/${id}`, {enabled});
  }

  /**
   * Delete company
   * @param id
   */
  deleteCompany(id: number): Observable<any> {
    return this.http.deleteData(`/admin/companies/${id}`);
  }
}
