import { Injectable } from '@angular/core';
import { AppSettings } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeathersService } from './feathers.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
  appSettings: AppSettings = new AppSettings();

  constructor(
    private http: HttpClient,
    private feathers: FeathersService,
    private translate: TranslateService,
  ) {}

  patchData<T>(action: string, data: any): Observable<T> {
    const url = this.appSettings.API_server + action;

    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);

    return this.http.patch<T>(url, data, {
      headers,
    });
  }

  postData<T>(action: string, data: any): Observable<T> {
    const url = this.appSettings.API_server + action;
    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);

    return this.http.post<T>(url, data, {
      headers,
    });
  }

  deleteData<T>(action: string): Observable<T> {
    const url = this.appSettings.API_server + action;
    return this.http.delete<T>(url, {
      headers: new HttpHeaders()
        .set('Authorization', localStorage.getItem('feathers-jwt'))
        .set('Accept-Language', this.translate.currentLang),
    });
  }

  getData<T>(action: any, params: any = {}): Observable<T> {
    const url = this.appSettings.API_server + action;
    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);

    if (this.feathers.isAutentificated()) {
      return this.http.get<T>(url, {
        headers,
        params,
      });
    } else {
      return this.http.get<T>(url);
    }
  }

  getBlob(action: any): any {
    const url = this.appSettings.API_server + action;
    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);

    if (this.feathers.isAutentificated()) {
      return this.http.get(url, {
        headers,
        responseType: 'blob',
      });
    }
  }

  uploadFile(action: string, data: any, reportProgress: boolean = false): any {
    const url = this.appSettings.API_server + action;
    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);


    if (this.feathers.isAutentificated()) {
      return this.http.post(url, data, {
        headers,
        reportProgress,
        responseType: 'json',
      });
    }
  }

  getNonEmptyParams(options, exceptionKeys?: string[]): object {
    if (!options) {
      return;
    }

    const keys = Object.keys(options);

    return keys.reduce((acc, key) => {
      if (options[key] || !!exceptionKeys?.includes(key)) {
        acc[key] = options[key];
      }
      return acc;
    }, {});
  }
}
