import { Directive, OnDestroy, OnInit, ElementRef, Input } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appTableHeader]',
})
export class TableHeaderDirective implements OnDestroy, OnInit {
  @Input() appTableHeaderLoaded;
  @Input() appTableHeader;
  offset = false;

  private listener: Subscription;

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    let container;
    if (this.appTableHeader === 'grid') {
      container = this.element.nativeElement.parentNode;
    } else {
      container = document.querySelector('.mainContainer .container');
    }

    this.listener = fromEvent(container, 'scroll')
      .pipe(
        map((e: WheelEvent) => (<any>container).pageYOffset || container.scrollTop),
        map(offset => {
          // condition is needed in order to toggle class only 1 time
          if (offset > 0 && !this.offset || offset === 0) {
            this.toggleShadow();
          }
        })
      ).subscribe();
  }

  ngOnDestroy() {
    this.listener.unsubscribe();
  }

  toggleShadow() {
    this.offset = !this.offset;
    if (this.element.nativeElement.tagName === 'DIV') {
      // in case if table has grid-layout
      return this.element.nativeElement.classList.toggle('header-shadowed');
    }
    document.querySelector('table').classList.toggle('shadowedHeader');
  }
}
