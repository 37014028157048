import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FeathersService } from './services/feathers.service';
import { localStorage } from './utils/storage';
import { ProjectVersionService } from '@shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    public translate: TranslateService,
    public feathers: FeathersService,
    private projectVersionService: ProjectVersionService,
  ) {
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('lang') || 'en');
  }

  ngOnInit() {
    this.projectVersionService.setProjectVersionToLocalStorage();
  }
}
