<div class="modal-wrapper playlist-modal">
  <div class="overlay" routerLink="/utv"></div>
  <div class="card">
    <div class="card-header">
      <div class="title">
        <span *ngIf="isNewPlaylist">{{'utv.create_playlist' | translate}}</span>
        <span *ngIf="!isNewPlaylist">{{'utv.edit_playlist' | translate}}</span>
      </div>
      <div class="close" routerLink="/utv"></div>
    </div>

    <div class="modal-body">
      <form [formGroup]="playlistDataFormGroup" class="modal-body-form">
        <section class="modal-body-section">
          <div>
            <div class="input thumbnail-input">
              <div class="image-empty-container">
                <img alt="empty list" class="image-empty" src="assets/logo_grey.svg">
              </div>
              <img class="thumbnail-image" [src]="imgURL" *ngIf="imgURL">

              <div *ngIf="image.invalid && (image.dirty || image.touched)" class="hint alert">
                <div *ngIf="image.errors?.required">
                  <span>*{{'utv.add_playlist_thumbnail' | translate}}</span>
                </div>
              </div>

              <input #imageFileInput
                      type="file"
                      accept="image/png, image/jpeg"
                      class="hidden_input"
                      (change)="previewThumbnailImage(imageFileInput.files)" />
              <button
                (click)="imageFileInput.click()"
                class="upload-thumbnail-button">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3488 5.16278H6.83719V0.651164C6.83719 0.445641 6.55811 0 5.99998 0C5.44184 0 5.16276 0.445665 5.16276 0.651164V5.16281H0.651161C0.445663 5.16278 0 5.44186 0 5.99998C0 6.55809 0.445663 6.83719 0.651161 6.83719H5.16279V11.3488C5.16279 11.5543 5.44184 12 6 12C6.55816 12 6.83721 11.5543 6.83721 11.3488V6.83719H11.3488C11.5543 6.83719 12 6.55814 12 5.99998C12 5.44181 11.5543 5.16278 11.3488 5.16278Z" fill="#14BFFB"/>
                </svg>
                <span>{{'utv.thumbnail_picture' | translate}}</span>
              </button>
            </div>

            <div class="input name-input">
              <app-material-input formControlName="name"
                                  class="material-input"
                                  [maxlength]="50"
                                  [label]="'utv.playlist_name' | translate">
              </app-material-input>

              <div class="hint" [ngClass]="{ 'alert': name.invalid && (name.dirty || name.touched) }">
                *{{'utv.write_playlist_name' | translate}}
              </div>
            </div>

            <div class="input textarea-input">
              <app-material-textarea
                [formControl]="playlistDataFormGroup.get('description')"
                [label]="'utv.playlist_description' | translate"
                [maxlength]="500">
              </app-material-textarea>

              <div class="hint" [ngClass]="{ 'alert': description.invalid && (description.dirty || description.touched) }">
                *{{'utv.write_playlist_description' | translate}}
              </div>
            </div>
          </div>

          <div class="action">
            <div class="action-left-side">
              <button *ngIf="!isNewPlaylist" class="button" (click)="confirmDeletePlaylistDialog()">
                {{'utv.delete_playlist' | translate}}
              </button>
            </div>
            <div class="action-right-side">
              <button class="button" routerLink="/utv">
                {{'common.cancel' | translate}}
              </button>
              <button class="button accent" (click)="savePlaylist()">
                {{'common.save' | translate}}
              </button>
            </div>
          </div>
        </section>

        <section class="modal-body-section dnd-section">
          <div class="input video-search-input">
            <app-material-select 
              formControlName="videos"
              class="material-select"
              [options]="allVideos"
              [multiSelect]="true"
              [showValue]="'name'"
              [showSelectAll]="false"
              [search]="true"
              [label]="'common.search' | translate"
              [isAllOptionsDisabled]="isVideosLimit"
            >
            </app-material-select>
          </div>
          <div *ngIf="playlistVideos.length" class="drag-list-header">
            <div class="icon">
            </div>
            <div class="video">
              {{'utv.video' | translate}}
            </div>
            <div class="title">
              {{'utv.title' | translate}}
            </div>
          </div>
          <div
            cdkDropList
            cdkDropListLockAxis="y"
            cdkDropListOrientation="vertical"
            class="video-list"
            (cdkDropListDropped)="drop($event)">
            <div *ngFor="let video of playlistVideos; index as i" cdkDrag class="video-box">
              <div class="drag-icon">
                <svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H0V2H16V0ZM0 6H16V4H0V6Z" fill="#3C474B"/>
                </svg>
              </div>
              <div class="video-thumbnail">
                <img [src]="video.thumbnail_url" height="200" *ngIf="video.thumbnail_url">
              </div>
              <div class="video-name">
                {{video.name}}
              </div>
              <div class="video-controls">
                <button class="button" (click)="deleteVideoFromPlaylist(i, video.id)">
                  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00016 10.6667C1.00016 11.4 1.60016 12 2.3335 12H7.66683C8.40016 12 9.00016 11.4 9.00016 10.6667V2.66667H1.00016V10.6667ZM9.66683 0.666667H7.3335L6.66683 0H3.3335L2.66683 0.666667H0.333496V2H9.66683V0.666667Z" fill="#3C474B"/>
                  </svg>
                  <span>
                    {{'common.delete' | translate}}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</div>
