import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Category } from '../../../../models/category';
import { UtvService } from '../../../../services/utv.service';

@Component({
  selector: 'app-video-category-view',
  templateUrl: './video-category-view.component.html',
  styleUrls: ['./video-category-view.component.scss'],
})
export class VideoCategoryViewComponent implements OnInit, OnDestroy {
  public category: Category;

  public videos: [] = [];
  public playlists: [] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private utvService: UtvService
  ) { }

  ngOnInit() {
    this.getCategory();
    this.utvService.getCategory(this.route.snapshot.params.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCategory() {
    this.utvService.category
      .pipe(
        filter((response: any) => {
          return response.created_at;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.category = result;
        this.videos = this.category.attached.videos;
        this.playlists = this.category.attached.video_playlists;
      });
  }
}
