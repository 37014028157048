import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DataInterface {
  id?: number;
  headerTxt: string;
  bodyTxt: string;
  actionPrimary: string;
  actionSecondary: string;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataInterface,
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
  ) { }

  ngOnInit() {
  }

  confirm() {
    if (this.data.id === undefined) {
      this.dialogRef.close({confirmed: true});
      return;
    }
    this.dialogRef.close({id: this.data.id});
  }

}
