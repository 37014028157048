import { Injectable } from '@angular/core';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';

@Injectable({
  providedIn: 'root',
})
export class PdfRendererService {

  private _regExps = {
    image: /\.(jpeg|png|jpg)$/,
    pdf: /\.(pdf)$/,
  };

  constructor() {
  }

  /**
   * PDF/image rendering
   * @param data - certificate
   * @param container - container to render pdf
   * @param pageNum - number of pages to render. 0 - no limit (default)
   */
  public renderMedia(data, container, pageNum = 0): void {
    const isPdf = this.checkFormat(data, 'pdf');

    if (isPdf) {
      this.setPdfView(data, container, pageNum);
    }
  }

  public isPdf(data): boolean {
    return this.checkFormat(data, 'pdf');
  }

  /**
   * Gets data from each page of pdf and renders it to container of choice
   * @param data
   * @param container
   * @param pages number of pages to render. If value != true (e.g. 0) gets number of available in pdf
   */
  public setPdfView(data, container: HTMLElement, pages: number) {
    container.innerHTML = '';
    (pdfjs.getDocument(data) as any).then((pdf) => {
      for (let i = 0; i < (pages || pdf.numPages); i++) {
        renderPage(pdf, i + 1);
      }
    });

    // todo perhaps should be in worker
    function renderPage(pdf, pageNum) {
      pdf.getPage(pageNum).then((page) => {
        const scale = 1.5;
        const viewport = page.getViewport(scale);
        // Prepare canvas using PDF page dimensions
        const canvas: any = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        // styles in js is bad thing to do but in this case it is inevitable
        canvas.style.cssText = 'max-width: 100%; max-height: 100%';
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        page.render(renderContext);
        container.appendChild(canvas);
      });
    }
  }

  /**
   * Checks for format of content
   * @param data link to media
   * @param format acceptable values: 'image'(default), 'pdf'
   */
  private checkFormat(data, format: string = 'image'): boolean {
    return this._regExps[format].test(data);
  }

}
