import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Playlist } from '@models/playlist';
import { UtvService } from '@services/utv.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { EditPlaylistAction } from '../../../interfaces';

@UntilDestroy()
@Component({
  selector: 'app-video-playlists',
  templateUrl: './video-playlists.component.html',
  styleUrls: ['./video-playlists.component.scss'],
})
export class VideoPlaylistsComponent implements OnInit {
  public playlists: Playlist[] = [];

  private totalPlaylistsNumber: number;
  private playlistsAreLoading: boolean;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private utvService: UtvService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.initPlaylistSubscribtions();
    this.utvService.getPlaylists().pipe(untilDestroyed(this)).subscribe();
  }

  initPlaylistSubscribtions() {
    this.playlistsAreLoading = true;
    this.utvService.playlists
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result) => {
         if (result.data) {
          this.totalPlaylistsNumber = result.total;
          this.playlists = result.data;
        }
        this.playlistsAreLoading = false;
      },
      (error) => {
        this.playlistsAreLoading = false;
        this.handleError(error);
      });
  }

  playlistsOnScroll(event) {
    // visible height + pixel scrolled >= total height - a coefficient
    // (since sometimes when scrolling, 1 pixel is not enough to trigger the condition)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
      if (this.playlists.length < this.totalPlaylistsNumber && !this.playlistsAreLoading) {
        this.playlistsAreLoading = true;
        this.utvService.getPlaylists(this.playlists.length).pipe(untilDestroyed(this)).subscribe();
      }
    }
  }

  handleError(error: Error) {
    this.utvService.showNotification(error.message, null, 3000, 'error');
  }

  viewPlaylist(id, event) {
    this.router.navigate(['/utv/playlist/view/' + id]);
  }

  editPlaylist(id, event) {
    this.router.navigate(['/utv/playlist/edit/' + id ]);
  }

  confirmDeletePlaylistDialog(id, event) {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_playlist'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.id) {
          this.removePlaylist(result.id);
        }});
  }

  private removePlaylist(id: Playlist['id']): void {
    this.utvService.removePlaylist(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response) => {
        this.utvService.removePlaylistById(response.id);
        this.utvService.showNotification(this.translate.instant('utv.playlist_removed'), null, 3000, 'success');
      });
  }
}
