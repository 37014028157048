<ng-container *ngIf="elementLength > itemsPerPage">
  <div class="page page-previous"
       (click)="selectPage( this.activePage - 1 )">
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_10903)">
      <path d="M6.96897 7.74984L6.71935 8L6.96897 8.25016L9.77241 11.0597L9.33301 11.4991L5.83388 8L9.33301 4.50087L9.77241 4.94027L6.96897 7.74984Z" fill="#9899A0" stroke="#9899A0" stroke-width="0.708333"/>
      </g>
      <defs>
      <clipPath id="clip0_1_10903">
      <rect width="16" height="16" rx="4" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  </span>
  </div>
  <div class="page"
       (click)="selectPage( i )"
       [ngClass]="{'active': activePage === i }"
       *ngFor="let page of pages; index as i">
    {{i + 1}}
  </div>
  <div class="page page-next" (click)="selectPage( this.activePage + 1 )">
    <span>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_10907)">
        <path d="M6.19771 11.0597L9.01586 8.23545L9.25079 8L9.01586 7.76455L6.19771 4.94026L6.66656 4.4714L10.1952 8L6.66656 11.5286L6.19771 11.0597Z" fill="#9899A0" stroke="#9899A0" stroke-width="0.666667"/>
        </g>
        <defs>
        <clipPath id="clip0_1_10907">
        <rect width="16" height="16" rx="4" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </span>
  </div>
</ng-container>
