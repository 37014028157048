import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { MatMenuAnchorComponent } from './mat-menu-anchor/mat-menu-anchor.component';
import { MatMenuOptionsComponent } from './mat-menu-options/mat-menu-options.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ContentChild(MatMenuAnchorComponent, { static: true }) anchor: MatMenuAnchorComponent;
  @ContentChild(MatMenuOptionsComponent, { static: true }) options: MatMenuOptionsComponent;

  @Output() select: EventEmitter<any>;
  private _subs: Subscription;

  constructor() {
    this.select = new EventEmitter();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this._subs = new Subscription();
    this._subs.add(
      this.anchor.clickEvent.subscribe(event => {
        this.options.showMenu = !this.options.showMenu;
      })
    );

    this._subs.add(
      this.options.clickEvent.subscribe(event => {
        this.select.emit(event);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
