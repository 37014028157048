<div class="modal-container">
  <div class="modal-header">
    <span>
      {{data.headerTxt}}
    </span>
    <button class="cancel" appIcon="cancel" mat-dialog-close></button>
  </div>
  <div class="modal-body">
    {{data.bodyTxt}}

    <div class="actions">
      <button class="modal-actions-secondary" mat-dialog-close>
        {{data.actionSecondary}}
      </button>
      <button class="modal-actions-primary accent" (click)="confirm()">
        {{data.actionPrimary}}
      </button>
    </div>
  </div>
</div>