import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://5312293ae34b4eb78b800d6da08e4627@sentry.io/1481055',
    environment: environment.environment,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}
