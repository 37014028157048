import { Injectable } from '@angular/core';
import packageJson from '../../package.json';

@Injectable({
  providedIn: 'root',
})
export class ProjectVersionService {

  setProjectVersionToLocalStorage(): void {
    localStorage.setItem('project-version',  packageJson.version);
  }
}
