<div class="modal">
  <header class="modal-header">
    <div class="modal-header-title">
      Upload files {{uploadedFilesNumber()}}/{{uploadProgressInfos.length}}
    </div>

    <div class="upload-file-item__item-action-group">
      <button (click)="toggleModalMode()" [ngClass]="{'expand': !fullSizeModal}" class="upload-file-item__item-action">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.41 0.590088L6 5.17009L10.59 0.590088L12 2.00009L6 8.00009L0 2.00009L1.41 0.590088Z" fill="white"/>
        </svg>
      </button>

      <button (click)="closeModal()" class="upload-file-item__item-action">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white"/>
        </svg>
      </button>
    </div>
  </header>

  <div *ngIf="fullSizeModal" class="modal-body">
    <section *ngFor="let progressInfo of uploadProgressInfos; index as i" class="loading-file-section">
      <div class="file-name">
        {{progressInfo.file.name}}
      </div>

      <div *ngIf="progressInfo.status === 'progress'" class="status-icon progress-icon">
        <mat-progress-spinner
            class="example-margin"
            [diameter]="21"
            [strokeWidth]="2"
            [value]="progressInfo.value">
        </mat-progress-spinner>

        <!-- <div class="delete-icon" (click)="cancelDownloadCurrent()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#14BFFB"/>
          </svg>
        </div> -->
      </div>

      <div *ngIf="progressInfo.status === 'queue'" class="status-icon queue-icon">
        <div class="delete-icon" (click)="cancelDownloadScheduled(i)">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#14BFFB"/>
          </svg>
        </div>
      </div>

      <div *ngIf="progressInfo.status === 'uploaded'" class="status-icon uploaded-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#14BFFB"/>
          </svg>
      </div>

      <div *ngIf="progressInfo.status === 'error'" class="status-icon error-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" fill="#F11C48"/>
        </svg>
      </div>

    </section>
  </div>

</div>