import {Trainer} from './trainer';
export class User {
  'id': number;
  'selected': boolean;
  'email': string;
  'first_name': string;
  'last_name': string;
  'billing_address': string;
  'telephone': string;
  'country_id': number;
  'city_id': number;
  'postcode_id': number;
  'profiles': Trainer[];
  'country': {
    'name': string;
  };
  'city': {
    'name': string;
  };
  'postcode': {
    'postcode': string;
  };
}

