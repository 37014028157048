<link href="https://cdn.quilljs.com/1.2.2/quill.snow.css" rel="stylesheet">
<div class="modal-wrapper">
  <div class="overlay" routerLink="../"></div>
  <div class="card">
    <div class="card-header">
      <div class="title">
        {{'content.' + tabType | translate}}
      </div>
      <div class="close" routerLink="../"></div>
    </div>
    <div class="card-body" [ngClass]="{ 'no-title': tabType === 'mail'}">
      <div class="title-input input" *ngIf="tabType !== 'mail'">
        <input type="text" [(ngModel)]="data.title">
      </div>
      <quill-editor *ngIf="tabType !== 'mail'" [readOnly]="!data.content" [(ngModel)]="data.content" [modules]="quillConfig">
      </quill-editor>
      <div *ngIf="tabType === 'mail' && data.content" [innerHtml]="data.content | safe"></div>
    </div>
    <div class="action" *ngIf="tabType !== 'mail'">
      <button class="button accent" (click)="save()">
        {{'common.save' | translate}}
      </button>
    </div>
  </div>
</div>
