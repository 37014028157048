import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { Video } from '../../../models/video';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { VideoPlaybackModalComponent } from '../../modals/video-playback-modal/video-playback-modal.component';

@Component({
  selector: 'app-video-list-item',
  templateUrl: './video-list-item.component.html',
  styleUrls: ['./video-list-item.component.scss'],
})
export class VideoListItemComponent {

  @Input()
    video: Video;

  @Input()
    index: number;

  @Output()
    remove = new EventEmitter<{ id: number; index: number }>();

  @Output()
    cancel = new EventEmitter<{ id: number }>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) { }

  removeVideo(id: number) {
    this.remove.emit({
      id,
      index: this.index,
    });
  }

  confirmDeleteVideoDialog(id, event) {
    event.stopPropagation();
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_video'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.id) {
          this.removeVideo(result.id);
        }
      });
  }

  editVideoDialog(id, event) {
    this.router.navigate(['/utv/video/' + id]);
  }

  playVideo(event, video) {
    event.stopPropagation();

    this.dialog.open(VideoPlaybackModalComponent, {
      data: {
        id: video.id,
        key: video.key,
        thumbnailUrl: video.thumbnail_url,
      },
    });
  }
}
