import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  SUBSCRIPTION_TYPES,
  VIDEO_STATUSES,
  UPLOAD_LIMITS,
} from '../../utils/enum';
import { Video } from '@models/video';
import { UtvService } from '@services/utv.service';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-utv',
  templateUrl: './utv.component.html',
  styleUrls: ['./utv.component.scss'],
})
export class UtvComponent implements OnInit, OnDestroy {
  @ViewChild('videoList') videoList: ElementRef;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  public videos: Video[] = [];
  public uploadProgressInfos = [];
  public isLoadingModalOpen = false;
  public formGroupCertificate: UntypedFormGroup;

  private selectedFiles: FileList;
  private fileInfos: Observable<any>;
  private currentTab = 'ALL';
  private uploadFileSub: Subscription;
  private totalVideosNumber: number;
  private videosAreLoading: boolean;

  constructor(
    public dialog: MatDialog,
    private utvService: UtvService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  get videosExist() {
    return this.videos && this.videos.length > 0;
  }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    if (this.isLoadingModalOpen) {
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit() {
    this.initSubscribtions();
    this.subscribeToNotifications();
    this.initForm();
    this.utvService.getVideoList();
  }

  ngOnDestroy() {
    this.utvService.resetEditedVideo();
    this.utvService.resetDeletedVideo();
    this.utvService.resetVideoList();
  }

  initForm() {
    this.formGroupCertificate = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required, this.strSpacesControl]),
      logo_link: new UntypedFormControl('', [Validators.required]),
      logo_base64: new UntypedFormControl(''),
    });
  }

  videosOnScroll(event) {
    // visible height + pixel scrolled >= total height - a coefficient
    // (since sometimes when scrolling, 1 pixel is not enough to trigger the condition)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
      if (this.videos.length < this.totalVideosNumber && !this.videosAreLoading) {
        this.videosAreLoading = true;
        this.utvService.getVideoList(this.videos.length);
      }
    }
  }

  strSpacesControl(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  initSubscribtions() {
    this.videosAreLoading = true;
    this.utvService.videoList
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result) => {
        if (result.data) {
          const filteredVideos = result.data.filter((newVideo) => !this.videos.find((currentVideo) => newVideo.id === currentVideo.id));

          this.totalVideosNumber = result.total;
          this.videos = this.videos.concat(filteredVideos);
        }
        this.videosAreLoading = false;
      }, (error) => {
        this.videosAreLoading = false;
        this.handleError(error);
      });

    this.utvService.deletedVideo
      .pipe(
        filter((response: any) => response?.id),
        untilDestroyed(this),
      )
      .subscribe((result) => {
        this.removeVideoFromList(result.id);
      }, (error) => {
        this.handleError(error);
      });

    this.utvService.editedVideo
      .pipe(
        filter((response) => response?.id),
        untilDestroyed(this),
      )
      .subscribe((result) => {
        this.replaceVideoInList(result);
      }, (error) => {
        this.handleError(error);
      });
  }

  removeVideoFromList(id: number) {
    const index = this.videos.findIndex((element) => {
      return element.id === id;
    });
    this.videos.splice(index, 1);
    this.utvService.showNotification(this.translate.instant('utv.video_removed'), null, 3000, 'success');
  }

  replaceVideoInList(video) {
    const index = this.videos.findIndex((element) => {
      return element.id === video.id;
    });
    this.videos[index] = video;
    this.utvService.showNotification(this.translate.instant('utv.video_edited'), null, 3000, 'success');
  }

  cancelFilesUploading(event) {
    this.uploadProgressInfos = [];
    this.selectedFiles = null;
    this.uploadFileSub.unsubscribe();
    this.isLoadingModalOpen = false;
  }

  removeVideo(payload: {id: number; index: number}): void {
    const {id, index} = payload;
    this.utvService.deleteFile(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe( event => {
        this.videos.splice(index, 1);
        this.utvService.showNotification(this.translate.instant('utv.video_removed'), null, 3000, 'success');
      }, (error) => {
        this.handleError(error);
      });
  }

  handleError(error: Error) {
    this.utvService.showNotification(error.message, null, 3000, 'error');
  }

  uploadFiles(event) {
    this.uploadProgressInfos = [];
    this.selectedFiles = event.target.files;
    const filesNumber = this.selectedFiles.length;

    if (filesNumber > UPLOAD_LIMITS.VIDEO_MAX_NUMBER) {
      this.utvService.showNotification(
        this.translate.instant('utv.exceedingVideoNumber', {
          number: UPLOAD_LIMITS.VIDEO_MAX_NUMBER,
        }), null, 3000, 'error');
      return;
    }

    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].size < UPLOAD_LIMITS.VIDEO_MAX_SIZE) {
          this.uploadProgressInfos.push( {value: 0, status: 'queue', file: this.selectedFiles[i]} );
        } else {
          this.utvService.showNotification(
            this.translate.instant('utv.exceedingFileSize', {
              name: this.selectedFiles[i].name,
              size: UPLOAD_LIMITS.VIDEO_MAX_SIZE_STR,
            }), null, 3000, 'error');
          this.uploadProgressInfos = [];
          this.selectedFiles = null;
          return;
        }
      }
      this.isLoadingModalOpen = true;
      this.upload(0);
    }
  }

  upload(idx) {
    const file = this.selectedFiles[idx];
    this.uploadFileSub = this.utvService.uploadVideo(file)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe( event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          this.uploadProgressInfos[idx].status = 'progress';
        } else if (event instanceof HttpResponse) {
          this.uploadProgressInfos[idx].status = 'uploaded';
          this.uploadFileSub.unsubscribe();
          this.saveFile(event, file.name);

          if (idx + 1 < this.uploadProgressInfos.length) {
            this.upload(idx + 1);
          } else {
            this.uploadProgressInfos = [];
            this.isLoadingModalOpen = false;
            this.utvService.showNotification(this.translate.instant('utv.video_uploaded'), null, 3000, 'success');
          }
        }
      },
      err => {
        this.uploadProgressInfos[idx].value = 0;
        this.uploadProgressInfos[idx].status = 'error';
        if (idx + 1 < this.uploadProgressInfos.length) {
          this.upload(idx + 1);
        } else {
          this.uploadProgressInfos = [];
          this.isLoadingModalOpen = false;
          this.handleError(err.error);
        }
      });
  }

  cancelUploads() {
    this.uploadFileSub.unsubscribe();
    this.closeFilesUploadDialog();
  }

  saveFile(event, fileName) {
    const data = {
      'name': fileName.substr(0, 50),
      'duration': event.body.video.duration,
      'key': event.body.video.key,
      'thumbnail_url': event.body.thumbnail.url,
      'thumbnail_key': event.body.thumbnail.key,
      'subscription_type': SUBSCRIPTION_TYPES.UTV,
      'status': VIDEO_STATUSES.HIDDEN,
      'body_parts': [],
    };

    this.utvService.createVideoFile(data)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((res: Video) => {
        this.videos.unshift(new Video(res));
      }, (error) => {
        this.handleError(error);
      });
  }

  openFilesUploadDialog() {
    this.isLoadingModalOpen = true;
  }

  closeFilesUploadDialog() {
    this.uploadProgressInfos = [];
    this.isLoadingModalOpen = true;
  }

  changeTab(type) {
    this.currentTab = type;
  }

  checkTab(type) {
    return this.currentTab === type;
  }

  cancelScheduledDownload(data) {
    this.uploadProgressInfos.splice(data.id, 1);
  }

  confirmDeleteVideoDialog() {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_video'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
    .afterClosed()
    .subscribe(() => {
      // TODO: Delete loading files and queue
    });
  }

  subscribeToNotifications() {
    this.utvService.userNotification.subscribe(
      (response: any) => {
        if (response === 'close') {
          this.snackBar.dismiss();
        } else {
          this.openSnackBar(response.notifyMessage, response.notifyAction, response.notifyDuration, response.class);
        }
      }
    );
  }

  openSnackBar(message: string, action: string, durationVal: number, additionalClass: string) {
    this.snackBar.open(
      message,
      action,
      {
        duration: durationVal,
        panelClass: additionalClass,
        verticalPosition: 'top',
      }
    );
  }
}
