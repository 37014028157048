<div class="modal-header" >
  <span *ngIf="!isEdit">
    {{'pricing.add_duration' | translate}}
  </span>
  <span *ngIf="isEdit">
    {{'pricing.edit_duration' | translate}}
  </span>
  <button class="cancel" appIcon="cancel" mat-dialog-close></button>
</div>
<div class="modal-body">
  <form [formGroup]="durationForm">
    <div class="input">
      <label for="">{{'pricing.duration' | translate}}</label>
      <input cdkFocusInitial 
             type="number" 
             formControlName="duration"
             [ngClass]="{'invalid': durationForm.controls.duration.invalid && durationForm.controls.duration.dirty}"
             placeholder="{{'pricing.duration' | translate}}">
      <app-errors [form]="durationForm" field="duration"></app-errors>
    </div>
    <div class="input">
      <label for="">{{'pricing.level' | translate}}</label>
      <app-select-option [options]="levelList" 
                         fieldToShow="level" 
                         [form]="durationForm">
      </app-select-option>
      
    </div>
    <div class="input range price-range" formArrayName="price">
      <label>{{'pricing.price_range' | translate}}</label>
      <input type="number" 
             [ngClass]="{'invalid': durationForm.controls.price.controls[0].invalid && durationForm.controls.price.controls[0].dirty}"
             formControlName="0"
             placeholder="{{'pricing.min' | translate}}">
      <span> - </span>
      <input type="number" 
             [ngClass]="{'invalid': durationForm.controls.price.controls[0].invalid && durationForm.controls.price.controls[1].dirty}"
             formControlName="1"
             placeholder="{{'pricing.max' | translate}}">
      <app-errors [form]="durationForm" field="price"></app-errors>
    </div>
  </form>
  <div class="actions" *ngIf="!attention">
    <button class="grey" (click)="attention = true" *ngIf="isEdit">
      {{'common.delete' | translate}}
    </button>
    <button class="accent" (click)="save()" [disabled]="durationForm.invalid">
      {{'common.save' | translate}}
    </button>
  </div>
  <div class="actions attention" *ngIf="attention">
    <div class="text">
      <span class="attention-title">
        {{'pricing.delete_duration' | translate}}
      </span>
      <span class="">
        {{'pricing.you_wont_be_able_to_undo' | translate}}
      </span>
    </div>
    <button (click)="attention = false">
      {{'common.no' | translate}}
    </button>
    <button (click)="remove()">
      {{'common.yes' | translate}}
    </button>
  </div>
</div>
