<label [for]="formControlName || defaultId"
       class="material_label"
       [ngClass]="{
         'material_label--focused': focused,
         'material_label--dirty': control.value,
         'material_label--disabled': control.disabled && styledDisabled,
         'material_label--invalid': isInvalid,
         'material_label--error': error
       }"
       #labelEl>
  {{label}}
</label>

<input
  [id]="formControlName || defaultId"
  class="material_input"
  [autofocus]="autofocus"
  [type]="type"
  (focus)="focus($event)"
  (blur)="blur($event)"
  [autocomplete]="autocomplete"
  [maxlength]="maxlength"
  [readonly]="readonly"
  [ngClass]="{
    'material_input--focused': focused,
    'material_input--disabled': control.disabled && styledDisabled,
    'material_input--invalid': isInvalid,
    'material_input--error': error
  }"
  [formControl]="control"
  (keydown)="keydown($event)"
  #inputEl
>

<div class="description" *ngIf="description">
  {{description}}
</div>

<div class="hints">
  <div class="error" *ngIf="error">
    *{{error}}
  </div>
  <div class="hint" *ngIf="hint && !error">
    {{hint}}
  </div>
</div>

<div *ngIf="maxlength" class="input-counter">
  {{counter}} / {{maxlength}}
</div>




