<label #labelEl
       (click)="toggleOptions()"
       [ngClass]="{
         'material_label--focused': showList,
         'material_label--dirty': this.control.value && !multiSelect,
         'material_label--disabled': control.disabled,
         'material_label--error': error
       }"
       class="material_label">
  <svg *ngIf="search" class="search-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33333 7.33333H7.80667L7.62 7.15333C8.27333 6.39333 8.66667 5.40667 8.66667 4.33333C8.66667 1.94 6.72667 0 4.33333 0C1.94 0 0 1.94 0 4.33333C0 6.72667 1.94 8.66667 4.33333 8.66667C5.40667 8.66667 6.39333 8.27333 7.15333 7.62L7.33333 7.80667V8.33333L10.6667 11.66L11.66 10.6667L8.33333 7.33333ZM4.33333 7.33333C2.67333 7.33333 1.33333 5.99333 1.33333 4.33333C1.33333 2.67333 2.67333 1.33333 4.33333 1.33333C5.99333 1.33333 7.33333 2.67333 7.33333 4.33333C7.33333 5.99333 5.99333 7.33333 4.33333 7.33333Z" fill="#C5C8C9"/>
  </svg>
  {{label}}
</label>

<div (click)="toggleOptions($event)"
     [ngClass]="{'material_input--focused': showList,
                 'material_input--disabled': control.disabled}"
     class="material_input">
  <span *ngIf="!search" class="material_input--value">
    {{inputLabel}}
  </span>
  <span *ngIf="search" class="material_input--value">
    <input #searchEl
           (input)="filter($event)"
           [placeholder]="showList ? ('common.startTyping' | translate) : ''"
           class="search"
           type="text">
  </span>
  <i class="material_input--icon-suffix">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z"></path>
    </svg>
  </i>
</div>

<div (click)="toggleOptions()" *ngIf="showList" class="overlay"></div>
<cdk-virtual-scroll-viewport *ngIf="showList"
                             [@listAnimationUp]="optionsUp"
                             [@listAnimation]="!optionsUp"
                             [maxBufferPx]="windowHeight"
                             [minBufferPx]="windowHeight"
                             [ngClass]="{'options--up': optionsUp}"
                             [style.height]="windowHeight + 'px'"
                             class="options"
                             [itemSize]="ITEM_SIZE">
  <li (click)="selectAll()"
      *ngIf="multiSelect && !hasMultiSelectOptions && showSelectAll"
      class="option">
    <div class="checkbox">
      <input [checked]="isSelectAll() && 'checked'" type="checkbox">
      <span class="checkmark"></span>
    </div>
    <span>{{'common.selectAll' | translate}}</span>
  </li>
  <ng-container *cdkVirtualFor="let option of filteredOptions; index as i">
    <li (click)="select(i)"
        [ngClass]="{'option--selected': option === control.value,
                    'option--locked': alreadySelectedItems.includes(i) && isElementLocked(),
                    'option--disabled': isAllOptionsDisabled && !option.checked,
                    'option--additional': showAdditionalValue }"
        class="option">
      <div class="option-inner-wrapper">
        <div *ngIf="multiSelect" class="checkbox">
          <input [checked]="option.checked && 'checked'" type="checkbox">
          <span class="checkmark"></span>
        </div>
        <span>
          {{getOptionLabel(option)}}
        </span>
      </div>
      <span *ngIf="showAdditionalValue" class="additional-label">
        {{getAdditionalOptionLabel(option)}}
      </span>
    </li>
  </ng-container>
</cdk-virtual-scroll-viewport>

<div class="hints">
  <div *ngIf="error" class="error">
    *{{error}}
  </div>
  <div *ngIf="hint && !error" class="hint">
    {{hint}}
  </div>
</div>
