import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { UtvComponent } from './components/utv/utv.component';
import { VideoModalComponent } from './components/utv/video-modal/video-modal.component';
import { VideoPlaylistsViewComponent } from './components/utv/video-playlists/video-playlists-view/video-playlists-view.component';
import { VideoPlaylistsEditComponent } from './components/utv/video-playlists/video-playlists-edit/video-playlists-edit.component';
import { VideoCategoryViewComponent } from './components/utv/video-categories/video-category-view/video-category-view.component';
import { VideoCategoryEditComponent } from './components/utv/video-categories/video-category-edit/video-category-edit.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'utv', component: UtvComponent, canActivate: [AuthGuard], children: [
    {
      path: 'video/:id',
      component: VideoModalComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'playlist/view/:id',
      component: VideoPlaylistsViewComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'playlist/create',
      component: VideoPlaylistsEditComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'playlist/edit/:id',
      component: VideoPlaylistsEditComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'category/view/:id',
      component: VideoCategoryViewComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'category/create',
      component: VideoCategoryEditComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'category/edit/:id',
      component: VideoCategoryEditComponent,
      canActivate: [AuthGuard],
    },
  ],
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  { path: 'utv/playlist', redirectTo: 'utv' },
  { path: 'utv/category', redirectTo: 'utv' },
  { path: 'utv/video', redirectTo: 'utv' },
  { path: '**', redirectTo: '/' },
];
export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
