import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule } from 'ngx-videogular';
import { DataService } from './services/data.service';
import { HttpService } from './services/http.service';
import { PricingService } from './services/pricing.service';
import { SentryErrorHandler } from './services/sentry.service';
import { InterceptorService } from './services/interseptor.service';
import { FeathersService } from './services/feathers.service';

import {GuardInterceptor} from './services/guard.interceptor';

import { SafePipe } from './pipes/safe.pipe';
import { FilterPipe } from './pipes/filter.pipe';

import { TableHeaderDirective } from './directives/table-header.directive';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { HeaderComponent } from './components/header/header.component';

import { EditorComponent } from './components/modals/editor/editor.component';
import { DurationModalComponent } from './components/modals/duration-modal/duration-modal.component';
import { LevelModalComponent } from './components/modals/level-modal/level-modal.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { ErrorsComponent } from './components/errors/errors.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { PictureModalComponent } from './components/modals/picture-modal/picture-modal.component';
import { VideoPlaybackModalComponent } from './components/modals/video-playback-modal/video-playback-modal.component';
import { UtvComponent } from './components/utv/utv.component';
import { VideoModalComponent } from './components/utv/video-modal/video-modal.component';
import { VideoListItemComponent } from './components/utv/video-list-item/video-list-item.component';
import { UploadFilesModalComponent } from './components/modals/upload-files-modal/upload-files-modal.component';
import { VideoPlaylistsComponent } from './components/utv/video-playlists/video-playlists.component';
import { VideoPlaylistsViewComponent } from './components/utv/video-playlists/video-playlists-view/video-playlists-view.component';
import { VideoPlaylistsEditComponent } from './components/utv/video-playlists/video-playlists-edit/video-playlists-edit.component';
import { VideoCategoriesComponent } from './components/utv/video-categories/video-categories.component';
import { VideoCategoryViewComponent } from './components/utv/video-categories/video-category-view/video-category-view.component';
import { VideoCategoryEditComponent } from './components/utv/video-categories/video-category-edit/video-category-edit.component';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { IconDirective } from './directives/icon.directive';

import localeNb from '@angular/common/locales/nb';
registerLocaleData(localeNb);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeftMenuComponent,
    HeaderComponent,
    SafePipe,
    FilterPipe,
    TableHeaderDirective,
    DurationModalComponent,
    LevelModalComponent,
    SelectOptionComponent,
    ErrorsComponent,
    TableHeaderDirective,
    EditorComponent,
    ConfirmModalComponent,
    PictureModalComponent,
    VideoPlaybackModalComponent,
    UtvComponent,
    VideoListItemComponent,
    VideoModalComponent,
    UploadFilesModalComponent,
    VideoPlaylistsComponent,
    VideoPlaylistsViewComponent,
    VideoPlaylistsEditComponent,
    VideoCategoriesComponent,
    VideoCategoryViewComponent,
    VideoCategoryEditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    UiSwitchModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    QuillModule,
    ReactiveFormsModule,
    ScrollingModule,
    DragDropModule,
    SharedComponentsModule,
    IconDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GuardInterceptor,
      multi: true,
    },
    FeathersService,
    DataService,
    PricingService,
    httpInterceptorProviders,
    HttpService,
    AuthGuard,
    SafePipe,
    FilterPipe,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
