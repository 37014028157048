import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtvService } from '../../../services/utv.service';

interface DataInterface {
  id: number;
  key: string;
  thumbnailUrl: string;
}

@Component({
  selector: 'app-video-playback-modal',
  templateUrl: './video-playback-modal.component.html',
  styleUrls: ['./video-playback-modal.component.scss'],
})
export class VideoPlaybackModalComponent implements OnInit {
  @ViewChild("videoPlayer") videoplayer: ElementRef;
  videoUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataInterface,
    private dialogRef: MatDialogRef<VideoPlaybackModalComponent>,
    private utvService: UtvService,
  ) { }

  ngOnInit() {
    this.utvService.getVideoUrl(this.data.id).subscribe(videoUrl => {
      this.videoUrl = videoUrl.url;
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
