import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormControl;
  @Input() options: any[];
  @Input() current: any;
  @Input() fieldToShow: string;
  @Input() fieldToSend: string;
  @Output() selected = new EventEmitter();
  focused = false;
  domClickEvent: Subscription;

  constructor() { }

  ngOnInit() {
  }

  toggleFocus() {
    this.focused = !this.focused;
    this.unsubscribe();

    setTimeout(() => {
      this.domClickEvent = fromEvent(document, 'click').subscribe(event => {
        this.focused = false;
        this.domClickEvent.unsubscribe();
      });
    }, 0);
  }

  selectOption(event: MouseEvent, option: any) {
    event.preventDefault();
    event.stopPropagation();
    this.focused = false;
    this.domClickEvent.unsubscribe();
    this.form.patchValue({
      trainer_level: option,
    });
  }

  unsubscribe() {
    this.domClickEvent && this.domClickEvent.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
