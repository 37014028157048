<div class="errors" *ngIf="form.controls[field].dirty && form.controls[field].invalid">
  <span *ngIf="form.controls[field].errors?.required">
    {{'errors.required' | translate}}
  </span>
  <span *ngIf="form.controls[field].errors?.max" 
        [translateParams]="{'number': form.controls[field].errors?.max.max}"
        [translate]="'errors.max'">
  </span>
  <span *ngIf="form.controls[field].errors?.min"
        [translateParams]="{'number': form.controls[field].errors?.min.min}"
        [translate]="'errors.min'">
  </span>
</div>