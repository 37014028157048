import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeathersService } from './services/feathers.service';
import { Router } from '@angular/router';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    public feathers: FeathersService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.feathers.isAutentificated()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
