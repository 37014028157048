<div class="main-wrapper" *ngIf="feathers.isAutentificated()">
  <header>
    <app-header></app-header>
  </header>
  <aside>
    <app-left-menu></app-left-menu>
  </aside>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>
<app-login *ngIf="!feathers.isAutentificated()"></app-login>
