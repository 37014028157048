import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { PricingService } from '../../../services/pricing.service';

@Component({
  selector: 'app-level-modal',
  templateUrl: './level-modal.component.html',
  styleUrls: ['./level-modal.component.scss'],
})
export class LevelModalComponent implements OnInit {
  levelForm: any;
  isEdit = false;
  attention = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pricingService: PricingService
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {
        level: '',
        trainer_percent: '',
      };
    } else {
      this.isEdit = true;
    }
    this.levelForm = new UntypedFormGroup({
      level: new UntypedFormControl(this.data.level, [Validators.required, Validators.min(0), Validators.max(100)]),
      trainer_percent: new UntypedFormControl(+this.data.trainer_percent, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  save() {
    // todo temporal stuff. Should be changed when levels are permitted to have more than just 3 digit numbers
    this.levelForm.value.level = this.levelForm.value.level.toString();
    if (this.isEdit) {
      this.pricingService.edit(this.levelForm.value, 'levels', this.data.id);
    } else {
      this.pricingService.create(this.levelForm.value, 'levels');
    }
  }

  remove() {
    this.pricingService.remove('levels', this.data.id);
  }
}
