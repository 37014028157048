<div class="overlay" *ngIf="showMenu" (click)="showMenu = false">

</div>
<div class="menu-options" *ngIf="showMenu" [@menu]>
  <ng-content>

  </ng-content>
</div>


