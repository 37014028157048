import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AppSettings } from '../../config/config';
import { DataService } from '../../services/data.service';
import { FeathersService } from '../../services/feathers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMAIL_REG_EXP } from '@shared/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  appSettings: AppSettings = new AppSettings;
  user: any;
  messages: string [];
  wrongCredentials: boolean;
  showRestore: boolean;

  readonly emailRegexp = EMAIL_REG_EXP;

  constructor(
    private feathers: FeathersService,
    private router: Router,
    private http: HttpClient,
    private data: DataService,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.wrongCredentials = false;
    this.showRestore = false;
    this.user = {
      email: '',
      password: '',
    };
    this.messages = [];
  }

  login(email: string, password: string) {
    this.messages.length = 0;
    if (!email || !password) {
      this.messages.push('login.incomplete');
      return;
    }

    // try to authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      email,
      password,
      dashboard: 'admin',
    })
      // navigate to base URL on success
      .then((res) => {
        this.data.setAdmin(res.user);
        this.data.refreshAllInfo();
        this.router.navigate(['/utv']);
      })
      .catch(err => {
        this.wrongCredentials = true;
      });
  }

  restore(email) {
    this.http.patch(this.appSettings.API_server + '/users?prk', {email: email})
      .subscribe({
        next: () => {
          this.showRestore = false;
          this.user = {
            email: '',
            password: '',
          };
        },
        error: (error) => {
          this.snackbar.open(error.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
      });
  }
}
