<div class="row video-list-item">
  <div class="column">
    <div class="video-list-item__badge-image">
      <div class="image-wrapper" (click)="playVideo($event, video)">
        <img stopPropagation [src]="video.thumbnail_url" alt="company logo">
        <div class="action-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.5 14.5V5.5L14.5 10L7.5 14.5Z" fill="#F11C48"/>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__descrtiption">
      {{video.name}}
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__descrtiption">
      {{'utv.' + (video.subscription_type | lowercase) | translate}}
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__descrtiption">
      <span *ngFor="let bodyPart of video.body_parts; index as i">
        {{bodyPart.name[0].translation}}
        <span class="separator" *ngIf="video.body_parts.length !== i+1">,</span>
      </span>
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__descrtiption capitalize">
      {{'utv.' + (video.status | lowercase) | translate}}
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__descrtiption">
      {{video.duration * 1000 | date:'HH:mm:ss':'UTC'}}
    </div>
  </div>

  <div class="column">
    <div class="video-list-item__item-action-group">
      <button (click)="confirmDeleteVideoDialog(video.id, $event)" class="button delete-btn">{{'utv.table.delete' | translate}}</button>
      <button (click)="editVideoDialog(video.id, $event)" class="button edit-btn">{{'utv.table.edit' | translate}}</button>
    </div>
  </div>
</div>
