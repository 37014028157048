import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyDetailsComponent } from './modals/company-details/company-details.component';
import { MaterialInputComponent } from './material/material-input/material-input.component';
import { MaterialSelectComponent } from './material/material-select/material-select.component';
import { MaterialTextareaComponent } from './material/material-textarea/material-textarea.component';
import { ChipsComponent } from './material/chips/chips.component';
import { MenuComponent } from './material/menu/menu.component';
import { MatMenuOptionsComponent } from './material/menu/mat-menu-options/mat-menu-options.component';
import { MatMenuAnchorComponent } from './material/menu/mat-menu-anchor/mat-menu-anchor.component';
import { MatMenuOptionComponent } from './material/menu/mat-menu-option/mat-menu-option.component';
import { MaterialCheckboxComponent } from './material/material-checkbox/material-checkbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconDirective } from '../../directives/icon.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    PaginationComponent,
    SearchFieldComponent,
    CompanyDetailsComponent,
    MaterialInputComponent,
    MaterialSelectComponent,
    MaterialTextareaComponent,
    ChipsComponent,
    MenuComponent,
    MatMenuOptionsComponent,
    MatMenuAnchorComponent,
    MatMenuOptionComponent,
    MaterialCheckboxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IconDirective,
    ScrollingModule,
  ],
  exports: [
    PaginationComponent,
    SearchFieldComponent,
    MaterialInputComponent,
    MaterialSelectComponent,
    MaterialTextareaComponent,
    ChipsComponent,
    MenuComponent,
    MatMenuOptionsComponent,
    MatMenuAnchorComponent,
    MatMenuOptionComponent,
    MaterialCheckboxComponent,
  ],
  entryComponents: [
    CompanyDetailsComponent,
  ],
  providers: [
    DatePipe,
  ],
})
export class SharedComponentsModule { }
