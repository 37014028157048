<header>
  <div class="header-container">

    <div class="nav-holder">
      <div class="nav-user" [matMenuTriggerFor]="menu">
        <div
          class="nav-user__photo"
          [style.backgroundImage]="userImage">
        </div>
        <div class="nav-user__info">
          {{admin.first_name}} {{admin.last_name}}
        </div>
      </div>

      <mat-menu #menu="matMenu" xPosition="before">
        <button
          mat-menu-item
          *ngFor="let action of actions"
          (click)="menuClick(action.action)"
          >
          {{ 'common.' + action.name | translate}}
        </button>
      </mat-menu>
    </div>

    <div class="language-holder">
      <div class="language-item" (click)="changeLang('en')" [class.active]="language === 'en'">
        En
      </div>
      <div class="language-item" (click)="changeLang('nb-nn')" [class.active]="language === 'nb-nn'">
        No
      </div>
    </div>

  </div>
</header>
