import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mat-menu-anchor',
  templateUrl: './mat-menu-anchor.component.html',
  styleUrls: ['./mat-menu-anchor.component.scss'],
})
export class MatMenuAnchorComponent implements OnInit {
  @Output() public clickEvent: EventEmitter<MouseEvent>;

  constructor() {
  }

  ngOnInit() {
    this.clickEvent = new EventEmitter();
  }

  @HostListener('click', ['$event']) click(event) {
    this.clickEvent.emit(event);
  }
}
