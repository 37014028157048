<div class="modal-wrapper category-modal">
  <div class="overlay" routerLink="/utv"></div>
  <div class="card" *ngIf="category">
    <div class="card-header">
      <div class="title">
        {{'utv.view_category' | translate}}
      </div>
      <div class="close" routerLink="/utv"></div>
    </div>

    <div class="modal-body">
      <section class="modal-body-section">
        <div class="category-image">
          <img *ngIf="category.image" [src]="category.image">
        </div>
        <div class="category-name">
          {{category.name}}
        </div>
        <div class="category-description">
          {{category.description}}
        </div>
      </section>

      <section class="modal-body-section">
        <div class="category-content">
          <div *ngFor="let video of videos" class="category-element-wrapper">
            <div class="category-element-image">
              <img *ngIf="video.thumbnail_url" [src]="video.thumbnail_url">
            </div>
            <div class="category-element-info">
              <div class="category-element-type">
                {{'utv.video' | translate}}
              </div>
              <div class="category-element-name">
                {{video.name}}
              </div>
            </div>
          </div>

          <div *ngFor="let playlist of playlists" class="category-element-wrapper">
            <div class="category-element-image">
              <img *ngIf="playlist.image" [src]="playlist.image">
            </div>
            <div class="category-element-info">
              <div class="category-element-type">
                {{'utv.playlist' | translate}}
              </div>
              <div class="category-element-name">
                {{playlist.name}}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
