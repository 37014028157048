import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { HttpService } from './http.service';
import { Duration } from '../models/duration';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  public dataStore: {
    priceList: any;
    trainingTypeList: any;
    durationList: any;
    levelList: any;
  };

  private _priceList: BehaviorSubject<any>;
  private _trainingTypeList: BehaviorSubject<any>;
  private _durationList: BehaviorSubject<any>;
  private _levelList: BehaviorSubject<any>;

  private get types() {
    return {
      levels: {
        url: '/trainer-level',
      },
      trainingTypes: {
        url: '/types-of-training-sessions',
      },
      durations: {
        url: '/types-of-training-durations',
      },
    };
  }

  constructor(private http: HttpService,
    private matDialog: MatDialog) {
    this.dataStore = {
      priceList: [],
      trainingTypeList: [],
      durationList: [],
      levelList: [],
    };
    this._priceList = new BehaviorSubject([]);
    this._trainingTypeList = new BehaviorSubject([]);
    this._durationList = new BehaviorSubject([]);
    this._levelList = new BehaviorSubject([]);
  }

  get priceList() {
    return this._priceList.asObservable();
  }
  get trainingTypeList() {
    return this._trainingTypeList.asObservable();
  }
  get durationList() {
    return this._durationList.asObservable();
  }
  get levelList() {
    return this._levelList.asObservable();
  }

  getPricingList() {
    return this.http.getData('/type-of-sessions').subscribe(response => {
      this.dataStore.priceList = response;
      this._priceList.next(Object.assign({}, response));
    }, error => {
      throw error;
    });
  }

  getLevelList() {
    return this.http.getData('/trainer-level').subscribe(response => {
      this.dataStore.levelList = response;
      this._levelList.next(Object.assign({}, response));
    }, error => {
      throw error;
    });
  }

  getTrainingTypeList() {
    return this.http.getData('/types-of-training-sessions').subscribe(response => {
      this.dataStore.trainingTypeList = response;
      this._trainingTypeList.next(Object.assign({}, response));
    }, error => {
      throw error;
    });
  }

  getDurationList() {
    return this.http.getData('/types-of-training-durations').pipe(
      map((response: any) => {
        response.data = response.data.map(el => new Duration(el));
        return response;
      })
    ).subscribe(response => {
      this.dataStore.durationList = response;
      this._durationList.next(Object.assign({}, response));
    }, error => {
      throw error;
    });
  }

  /**
   * Creates new item
   * @param data request body
   * @param type can be 'levels', 'trainingTypes', 'durations'
   */
  create(data: any, type: string) {
    this.http.postData(this.types[type].url, data).subscribe(() => {
      this.matDialog.closeAll();
      this.refreshAll();
    });
  }

  /**
   * Patches item
   * @param data request body
   * @param type can be 'levels', 'trainingTypes', 'durations'
   */
  edit(data, type: string, id: number) {
    this.http.patchData(`${this.types[type].url}/${id}`, data).subscribe(() => {
      this.matDialog.closeAll();
      this.refreshAll();
    });
  }

  /**
   * Removes item
   * @param type can be 'levels', 'trainingTypes', 'durations'
   * @param id item id
   */
  remove(type: string, id: number) {
    this.http.deleteData(`${this.types[type].url}/${id}`).subscribe(() => {
      this.matDialog.closeAll();
      this.refreshAll();
    });
  }

  private refreshAll() {
    this.getDurationList();
    this.getLevelList();
    this.getPricingList();
    this.getTrainingTypeList();
  }
}
