import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';

import { Observable, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { AppSettings } from '../config/config';
import { MatDialog } from '@angular/material/dialog';
import { Video } from '@models/video';
import { EditCategoryAction, EditPlaylistAction } from '../interfaces';
import { Playlist } from '@models/playlist';
import { tap } from 'rxjs/operators';
import { Response } from '@shared/interfaces';
import { Category } from '@models/category';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtvService {
  appSettings: AppSettings = new AppSettings();
  userNotification = new Subject();

  private readonly playlistsSubject$ = new BehaviorSubject<Response<Playlist>>({total: 0, limit: 0, data: [], skip: 0});
  private readonly categoriesSubject$ = new BehaviorSubject<Response<Category>>({total: 0, limit: 0, data: [], skip: 0});

  private _videoList: BehaviorSubject<any>;
  private _playlist: BehaviorSubject<any>;
  private _category: BehaviorSubject<any>;
  private _deletedVideo: BehaviorSubject<any>;
  private _editedVideo: BehaviorSubject<any>;
  private _editedPlaylist = new Subject<EditPlaylistAction>();
  private _editedCategory = new Subject<EditCategoryAction>();

  private API = {
    video: 'video-thumbnail-upload',
    playlist: 'video-playlists-image-upload',
    categorie:'categories-image-upload',
  };

  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private matDialog: MatDialog,
    private translate: TranslateService,
  ) {
    this._videoList = new BehaviorSubject([]);
    this._playlist = new BehaviorSubject({});
    this._category = new BehaviorSubject({});
    this._deletedVideo = new BehaviorSubject({});
    this._editedVideo = new BehaviorSubject({});
  }

  get videoList() {
    return this._videoList.asObservable();
  }

  get playlists(): Observable<Response<Playlist>> {
    return this.playlistsSubject$.asObservable();
  }

  get playlist() {
    return this._playlist.asObservable();
  }

  get categories(): Observable<Response<Category>> {
    return this.categoriesSubject$.asObservable();
  }

  get category() {
    return this._category.asObservable();
  }

  get deletedVideo() {
    return this._deletedVideo.asObservable();
  }

  get editedVideo() {
    return this._editedVideo.asObservable();
  }

  get editedPlaylist(): Observable<EditPlaylistAction> {
    return this._editedPlaylist.asObservable();
  }

  get editedCategory(): Observable<EditCategoryAction> {
    return this._editedCategory.asObservable();
  }

  getVideos() {
    const params = {
      // '$sort[created_at]': '-1', // sort by date
    };

    return this.http.getData('/videos', params);
  }

  getVideo(id: number): Observable<Video> {
    return this.http.getData<Video>('/videos/' + id);
  }

  createVideoFile(data: any) {
    return this.http.postData('/videos', data);
  }

  deleteFile(id: number) {
    return this.http.deleteData('/videos/' + id);
  }

  patchVideoFile(id: number, data: any) {
    return this.http.patchData('/videos/' + id, data);
  }

  resetEditedVideo(): void {
    this._editedVideo.next(null);
  }

  resetDeletedVideo(): void {
    this._deletedVideo.next(null);
  }

  resetVideoList(): void {
    this._videoList.next([]);
  }

  uploadVideo(file: File): Observable<HttpEvent<any>> {
    const url = this.appSettings.API_server + '/video-upload';
    const headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem('feathers-jwt'))
      .set('Accept-Language', this.translate.currentLang);

    const formData: FormData = new FormData();

    formData.append('video', file);

    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  uploadImage(type: string, file: File) {
    let url, form = '';
    switch (type) {
      case 'video':
        url = '/video-thumbnail-upload';
        form = 'video_thumbnail_photo';
        break;

      case 'playlist':
        url = '/video-playlists-image-upload';
        form = 'image';
        break;

      case 'category':
        url = '/categories-image-upload';
        form = 'image';
        break;
    }

    const formData: FormData = new FormData();
    formData.append(form, file);

    return this.http.uploadFile(url, formData);
  }

  getBodyParts() {
    return this.http.getData('/body-parts');
  }

  getVideoUrl(id: number): Observable<{ url: string }> {
    return this.http.getData<{ url: string }>('/videos/' + id + '/watch');
  }

  getVideoList(offset: number = 0, limit: number = 30, options?: any) {
    return this.http.getData(`/videos?$limit=${limit}&$skip=${offset}`, options)
      .subscribe(response => {
        this._videoList.next(response);
      }, error => {
        throw error;
      });
  }

  getPublishedVideoList(offset: number = 0, limit: number = 30) {
    return this.http.getData(`/videos?$limit=${limit}&$skip=${offset}&status[$in][]=PUBLISHED&$sort[created_at]=-1`)
      .subscribe(response => {
        this._videoList.next(response);
      }, error => {
        throw error;
      });
  }

//==========================================================================

  getPlaylists(offset: number = 0, limit: number = 30): Observable<Response<Playlist>> {
    return this.http.getData<Response<Playlist>>(`/admin-video-playlists?$limit=${limit}&$skip=${offset}`)
      .pipe(
        tap((response) => {
          const currentPlaylists = this.playlistsSubject$.value?.data ?? [];

          this.playlistsSubject$.next({
            ...response,
            data: [
              ...currentPlaylists,
              ...response.data.filter((playlist) => !currentPlaylists.find((currentPlaylist) => currentPlaylist.id === playlist.id)),
            ],
          });
        }),
      );
  }

  getPlaylist(id) {
    return this.http.getData('/admin-video-playlists/' + id)
      .subscribe(response => {
        this._playlist.next(response);
      }, (error) => {
        throw error;
      });
  }

  addPlaylist(playlist: Playlist): void {
    const playlists = this.playlistsSubject$.value;

    this.playlistsSubject$.next({
      ...playlists,
      data: [playlist, ...playlists.data],
      total: playlists.total + 1,
    });
  }

  removePlaylistById(id: Playlist['id']): void {
    const playlists = this.playlistsSubject$.value;

    this.playlistsSubject$.next({
      ...playlists,
      data: playlists.data.filter((playlist) => playlist.id !== id),
      total: playlists.total - 1,
    });
  }

  changePlaylist(updatedPlaylist: Playlist): void {
    const playlists = this.playlistsSubject$.value;

    this.playlistsSubject$.next({
      ...playlists,
      data: playlists.data.map((playlist) => playlist.id === updatedPlaylist.id ? updatedPlaylist : playlist),
    });
  }

  createPlaylist(data: Playlist): Observable<Playlist> {
    return this.http.postData<Playlist>('/admin-video-playlists', data)
      .pipe(
        tap((response) => {
          this._editedPlaylist.next({type: 'create', data: response});
        }),
      );
  }

  editPlaylist(id: number, data: Playlist): Observable<Playlist> {
    return this.http.patchData<Playlist>('/admin-video-playlists/' + id, data)
      .pipe(
        tap((response) => {
          this._editedPlaylist.next({type: 'edit', data: response});
        }),
      );
  }

  removePlaylist(id: Playlist['id']): Observable<Playlist> {
    return this.http.deleteData<Playlist>('/admin-video-playlists/' + id)
      .pipe(
        tap((response) => {
          this._editedPlaylist.next({type: 'remove', data: response});
        }),
      );
  }

//==========================================================================

  addCategory(category: Category): void {
    const categories = this.categoriesSubject$.value;

    this.categoriesSubject$.next({
      ...categories,
      data: [category, ...categories.data],
      total: categories.total + 1,
    });
  }

  removeCategoryById(id: Category['id']): void {
    const categories = this.categoriesSubject$.value;

    this.categoriesSubject$.next({
      ...categories,
      data: categories.data.filter((category) => category.id !== id),
      total: categories.total - 1,
    });
  }

  changeCategory(updatedCategory: Category): void {
    const categories = this.categoriesSubject$.value;

    this.categoriesSubject$.next({
      ...categories,
      data: categories.data.map((category) => category.id === updatedCategory.id ? updatedCategory : category),
    });
  }

  getCategories(offset: number = 0, limit: number = 30): Observable<Response<Category>> {
    return this.http.getData<Response<Category>>(`/admin-categories?$limit=${limit}&$skip=${offset}`)
      .pipe(
        tap((response) => {
          const currentCategories = this.categoriesSubject$.value?.data ?? [];

          this.categoriesSubject$.next({
            ...response,
            data: [
              ...currentCategories,
              ...response.data.filter((category) => !currentCategories.find((currentCategory) => currentCategory.id === category.id)),
            ],
          });
        }),
      );
  }

  getCategory(id) {
    return this.http.getData('/admin-categories/' + id)
      .subscribe(response => {
        this._category.next(response);
      }, (error) => {
        throw error;
      });
  }

  createCategory(data: Category): Observable<Category> {
    return this.http.postData<Category>('/admin-categories', data)
      .pipe(
        tap((response) => {
          this._editedCategory.next({type: 'create', data: response});
        }),
      );
  }

  editCategory(id: Category['id'], data: Category): Observable<Category> {
    return this.http.patchData<Category>('/admin-categories/' + id, data)
      .pipe(
        tap((response) => {
          this._editedCategory.next({type: 'edit', data: response});
        }),
      );
  }

  removeCategory(id: Category['id']): Observable<Category> {
    return this.http.deleteData<Category>('/admin-categories/' + id)
      .pipe(
        tap((response) => {
          this._editedCategory.next({type: 'remove', data: response});
        }),
      );
  }

//==========================================================================

  edit(id: number, data: any) {
    this.http.patchData('/videos/' + id, data)
      .subscribe((response) => {
        this._editedVideo.next(response);
      }, errResp => {
        this.showNotification(errResp.error.errors[0].message, null, 3000, 'error');
      });
  }

  removeVideo(id: number) {
    this.http.deleteData('/videos/' + id)
      .subscribe(() => {
        this.matDialog.closeAll();
        this._deletedVideo.next({id: id});
      }, errResp => {
        this.showNotification(errResp.error.errors[0].message, null, 3000, 'error');
      });
  }

  getCategoriesElements(limit: number = 300, offset: number = 0) {
    return forkJoin([
      this.http.getData(`/videos?$limit=${limit}&$skip=${offset}`),
      this.http.getData(`/admin-video-playlists?$limit=${limit}&$skip=${offset}`)
    ]);
  }

  getPublishedCategoriesElements(limit: number = 300, offset: number = 0) {
    return forkJoin([
      this.http.getData(`/videos?$limit=${limit}&$skip=${offset}&status[$in][]=PUBLISHED&$sort[created_at]=-1`),
      this.http.getData(`/admin-video-playlists?$limit=${limit}&$skip=${offset}`),
    ]);
  }

  showNotification(message: string, action?: string, durationVal?: number, additionalClass?: string) {
    if (message === 'close') {
      this.userNotification.next('close');
    } else {
      this.userNotification.next({
        notifyMessage: message,
        notifyAction: action,
        notifyDuration: durationVal,
        class: additionalClass,
      });
    }
  }
}
