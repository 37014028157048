import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { filter } from 'rxjs/operators';

import { Category } from '@models/category';
import { UtvService } from '@services/utv.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-video-categories',
  templateUrl: './video-categories.component.html',
  styleUrls: ['./video-categories.component.scss'],
})
export class VideoCategoriesComponent implements OnInit {
  public categories: Category[] = [];

  private totalCategoriesNumber: number;
  private categoriesAreLoading: boolean;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private utvService: UtvService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.initCategorySubscribtions();
    this.utvService.getCategories().pipe(untilDestroyed(this)).subscribe();
  }

  initCategorySubscribtions() {
    this.categoriesAreLoading = true;
    this.utvService.categories
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result: any) => {
        if (result.data) {
          this.totalCategoriesNumber = result.total;
          this.categories = result.data;
        }
        this.categoriesAreLoading = false;
      });

  }

  categoriesOnScroll(event) {
    // visible height + pixel scrolled >= total height - a coefficient
    // (since sometimes when scrolling, 1 pixel is not enough to trigger the condition)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
      if (this.categories.length < this.totalCategoriesNumber && !this.categoriesAreLoading) {
        this.utvService.getCategories(this.categories.length).pipe(untilDestroyed(this)).subscribe();
      }
    }
  }

  handleError(error: Error) {
    this.utvService.showNotification(error.message, null, 3000, 'error');
  }

  viewCategory(id, event) {
    this.router.navigate(['/utv/category/view/' + id]);
  }

  editCategory(id, event) {
    this.router.navigate(['/utv/category/edit/' + id ]);
  }

  confirmDeleteCategoryDialog(id, event) {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_category'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(
        filter((result) => !!result?.id),
        switchMap((result) => this.utvService.removeCategory(result.id)),
        untilDestroyed(this),
      )
      .subscribe(category => {
        this.utvService.removeCategoryById(category.id);
        this.utvService.showNotification(this.translate.instant('utv.category_removed'), null, 3000, 'success');
      });
  }
}
