import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import {
  SUBSCRIPTION_TYPES,
  VIDEO_STATUSES,
  UPLOAD_LIMITS,
} from '../../../utils/enum';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { UtvService } from '../../../services/utv.service';
import { Video } from '../../../models/video';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  public NAME_MAX_LENGTH = 50;

  public video: Video;
  public videoDataFormGroup: UntypedFormGroup;
  public subscriptionTypes;

  public bodyParts = [];

  public statuses = [];
  public playlists = [];

  public imgURL: any;
  public imgFile: any;
  public message: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utvService: UtvService,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  get name(): AbstractControl { return this.videoDataFormGroup.get('name'); }
  get thumbnail_url(): AbstractControl { return this.videoDataFormGroup.get('thumbnail_url'); }

  ngOnInit(): void {
    this.subscriptionTypes = Object.values(SUBSCRIPTION_TYPES).map((value) => {
      return {
        value,
        title: this.translate.instant(`utv.${value}`.toLocaleLowerCase()),
      };
    });
    this.statuses = Object.values(VIDEO_STATUSES).map((value) => {
      return {
        value,
        title: this.translate.instant(`utv.${value}`.toLocaleLowerCase()),
      };
    });

    this.getPlaylists();

    this.utvService.getVideo(this.route.snapshot.params.id)
      .pipe(untilDestroyed(this))
      .subscribe(video => {
        this.video = video;
        this.videoInit();
      });

    this.utvService.getBodyParts()
      .pipe(untilDestroyed(this))
      .subscribe(bodyParts => {
        this.bodyParts = this.transformBodyParts(bodyParts);
      });

    this.initVideoDataForm();
  }

  getPlaylists(): void {
    this.utvService.getPlaylists()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.utvService.playlists
      .pipe(
        filter((response: any) => {
          return response.total > 0;
        }),
        untilDestroyed(this),
      )
      .subscribe((result: any) => {
        this.playlists = result.data;
      });
  }

  initVideoDataForm() {
    this.videoDataFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH),
      ]),
      thumbnail_url: new UntypedFormControl('', [
        Validators.required,
      ]),
      subscription_type: new UntypedFormControl('', [
        Validators.required,
      ]),
      body_parts: new UntypedFormControl(''),
      status: new UntypedFormControl('', [
        Validators.required,
      ]),
      playlists: new UntypedFormControl(''),
    });
  }

  videoInit() {
    this.imgURL = this.video.thumbnail_url;
    this.videoDataFormGroup.patchValue({
      name: this.video.name,
      thumbnail_url: this.video.thumbnail_url,
      subscription_type: this.video.subscription_type,
      body_parts: this.transformBodyParts(this.video.body_parts),
      status: this.video.status,
      playlists: this.video.video_playlists,
    });
  }

  transformBodyParts(bodyParts) {
    return bodyParts?.map(bodyPart => {
      return {id: bodyPart.id, name: bodyPart.name[0].translation};
    });
  }

  removeItem(index: number, type) {
    if (this.videoDataFormGroup.controls[type]) {
      this.videoDataFormGroup.controls[type].value.splice(index, 1);
      this.videoDataFormGroup.controls[type].setValue(this.videoDataFormGroup.controls[type].value);
    }
  }

  previewThumbnailImage(files) {
    if (files.length === 0) {
      return;
    }
    this.imgFile = files[0];

    const mimeType = this.imgFile.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    if (this.imgFile.size > UPLOAD_LIMITS.IMG_MAX_SIZE) {
      this.utvService.showNotification(
        this.translate.instant('utv.exceedingFileSize', {
          name: this.imgFile.name,
          size: UPLOAD_LIMITS.IMG_MAX_SIZE_STR,
        }), null, 3000, 'error');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
      this.videoDataFormGroup.patchValue({
        thumbnail_url: reader.result,
      });
    };
  }

  saveVideo() {
    this.videoDataFormGroup.markAllAsTouched();
    if (this.videoDataFormGroup.invalid) {
      return;
    }

    if (this.imgFile) {
      this.uploadVideoImage();
    } else {
      this.saveVideoData();
    }
  }

  uploadVideoImage() {
    this.utvService.uploadImage('video', this.imgFile)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.videoDataFormGroup.patchValue({
          thumbnail_url: response.url,
        });
        this.saveVideoData();
      }, (error) => {
        throw error;
      });
  }

  saveVideoData() {
    const videoData: any = {};
    Object.keys(this.videoDataFormGroup.controls).forEach(key => {
      if (key === 'body_parts' || key === 'playlists') {
        videoData[key] = this.videoDataFormGroup.controls[key].value
          .map(element => {
            return element.id;
          });
      } else {
        videoData[key] = this.videoDataFormGroup.controls[key].value;
      }
    });

    this.utvService.edit(this.video.id, videoData);
    this.closeEditVideoDialog();
  }

  confirmDeleteVideoDialog() {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: this.video.id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_video'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result && result.id) {
          this.utvService.removeVideo(result.id);
          this.closeEditVideoDialog();
        }
      });
  }

  closeEditVideoDialog() {
    this.router.navigate(['/utv']);
  }
}
