import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-material-textarea',
  templateUrl: './material-textarea.component.html',
  styleUrls: ['./material-textarea.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialTextareaComponent,
    multi: true,
  }],
})
export class MaterialTextareaComponent implements OnInit, OnDestroy {

  @Input() autofocus: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() errors: any;
  @Input() description;
  @Input() formControl: UntypedFormControl;
  @Input() readonly;
  @Input() maxlength;
  @Input() allowSpaces = false;
  @Input() touchNeeded = true;
  @Input() styledDisabled = true;

  control: UntypedFormControl;
  focused: boolean;
  destroy$ = new Subject<void>();

  get counter() {
    return this.control.value ? this.control.value.length : '0';
  }

  get controlValue() {
    return this.control.value;
  }

  get isInvalid() {
    if (this.touchNeeded) {
      return (this.control.dirty || this.control.touched) && this.control.invalid;
    } else {
      return this.control.invalid;
    }
  }

  constructor() { }

  ngOnInit() {
    this.formInit();

    this.listenFormControl();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  formInit() {
    if (this.formControl) {
      this.control = this.formControl;
    }
  }

  /**
   * Listen form control
   */
  listenFormControl() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.allowSpaces) {
          this.whiteSpaceValidator(this.control);
        }
      });
  }

  /**
   * User shouldn't have possibility to start from whitespase
   * @param control
   */
  whiteSpaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;

    if (isWhitespace) {
      this.control.patchValue(this.controlValue.trim(), {
        emitEvent: false,
      });
    }
  }

  focus($event) {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  blur($event) {
    this.focused = false;
  }

}
