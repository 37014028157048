import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.scss']
})
export class UploadFilesModalComponent implements OnInit {

  @Input()
    uploadProgressInfos: any[];

  @Output()
    cancelAll = new EventEmitter();

  @Output()
    cancelCurrentDownload = new EventEmitter<{ id: number }>();

  @Output()
    cancelScheduledDownload = new EventEmitter<{ id: number }>();

  public fullSizeModal = true;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  toggleModalMode() {
    this.fullSizeModal = !this.fullSizeModal;
  }

  cancelDownloadCurrent(id) {
    // this.cancelCurrentDownload.emit({id: id});
  }

  cancelDownloadScheduled(id) {
    this.cancelScheduledDownload.emit({id: id});
  }

  uploadedFilesNumber() {
    return this.uploadProgressInfos.filter(video => {
      return video.status === 'uploaded'
    }).length;
  }

  closeModal() {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        headerTxt: this.translate.instant('utv.modal.uploading'),
        bodyTxt: this.translate.instant('utv.modal.confirm_cancel_uploading'),
        actionPrimary: this.translate.instant('utv.modal.yes_cancel'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      }
    })
    .afterClosed()
    .subscribe((result) => {
      if (result && result.confirmed) {
        this.cancelAll.emit();
      }
    });
  }
}
