/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { UPLOAD_LIMITS } from '@directives/enum';
import { Video } from '@models/video';
import { Playlist } from '@models/playlist';
import { UtvService } from '@services/utv.service';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-video-playlists-edit',
  templateUrl: './video-playlists-edit.component.html',
  styleUrls: ['./video-playlists-edit.component.scss'],
})
export class VideoPlaylistsEditComponent implements OnInit, OnDestroy {
  public playlist: Playlist;
  public playlistDataFormGroup: UntypedFormGroup;
  public allVideos: [] = [];
  public playlistVideos: Video[];
  public imgURL: any;
  public isNewPlaylist: boolean;

  private LIST_MAX_LIMIT = 300;
  private VIDEOS_LIMIT = 20;
  private destroy$ = new Subject<void>();
  private imgFile: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private utvService: UtvService,
    private translate: TranslateService,
  ) { }

  get name() { return this.playlistDataFormGroup.get('name'); }
  get description() { return this.playlistDataFormGroup.get('description'); }
  get image() { return this.playlistDataFormGroup.get('image'); }
  get videos() { return this.playlistDataFormGroup.get('videos').value; }
  get isVideosLimit(): boolean {
    return this.playlistVideos?.length >= this.VIDEOS_LIMIT;
  }

  ngOnInit() {
    this.initVideoDataForm();
    this.videoValueChanges();
    this.getVideoList();
    this.utvService.getPublishedVideoList(0, this.LIST_MAX_LIMIT);
    this.isNewPlaylist = this.route.snapshot.params.id === undefined ? true : false;
    if (!this.isNewPlaylist) {
      this.getPlaylist();
      this.utvService.getPlaylist(this.route.snapshot.params.id);
    } else {
      this.playlistInit();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPlaylist() {
    this.utvService.playlist
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.playlist = result;
        this.playlistInit();
      });
  }

  transformVideoList(videoList) {
    if (!videoList) {
      return [];
    }
    return videoList.map(video => {
      return {
        id: video.id,
        name: video.name,
        thumbnail_url: video.thumbnail_url,
      };
    });
  }

  getVideoList() {
    this.utvService.videoList
      .pipe(
        filter((response: any) => {
          return response.data;
        }),
        map((result: any) => {
          return this.transformVideoList(result.data);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.allVideos = result;
      });
  }

  initVideoDataForm() {
    this.playlistDataFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
      ]),
      description: new UntypedFormControl('', [
        Validators.required,
      ]),
      image: new UntypedFormControl('', [
        Validators.required,
      ]),
      videos: new UntypedFormControl(''),
    });
  }

  playlistInit() {
    if (this.playlist) {
      this.imgURL = this.playlist.image;
      this.playlistVideos = this.transformVideoList(this.playlist.videos);
      this.playlistDataFormGroup.patchValue({
        name: this.playlist.name,
        description: this.playlist.description,
        image: this.playlist.image,
        videos: this.transformVideoList(this.playlist.videos),
      });
    } else {
      this.imgURL = '';
      this.playlistVideos = [];
      this.playlistDataFormGroup.patchValue({
        name: '',
        description: '',
        image: '',
        videos: [],
      });
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.playlistVideos, event.previousIndex, event.currentIndex);
  }

  videoValueChanges() {
    this.playlistDataFormGroup.get('videos').valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.updateSortedVideoList(value);
      }, error => {
        throw error;
      });
  }

  updateSortedVideoList(videoList) {
    if (videoList.length > this.playlistVideos.length) {
      // adding video to playlist by using search
      const onlyInVideoList = videoList.filter(this.comparer(this.playlistVideos));
      this.playlistVideos = onlyInVideoList.concat(this.playlistVideos);
    } else {
      // deleting video from playlist by using search
      this.playlistVideos = this.playlistVideos.filter(this.comparer(videoList, false));
    }
  }

  handleError(error: Error) {
    this.utvService.showNotification(error.message, null, 3000, 'error');
  }

  comparer(otherArray, isAdding = true) {
    return (currentObj) => {
      const isResult = otherArray.filter(otherObj => {
        return otherObj.id === currentObj.id;
      }).length === 0;
      return isAdding ? isResult : !isResult;
    };
  }

  deleteVideoFromPlaylist(id: number, videoId: number) {
    this.playlistVideos.splice(id, 1);
    const remainingVideos = this.videos.filter(video => {
      return video.id !== videoId;
    });

    this.playlistDataFormGroup.patchValue({
      videos: remainingVideos,
    });
  }

  savePlaylist() {
    this.playlistDataFormGroup.markAllAsTouched();
    if (this.playlistDataFormGroup.invalid) {
      return;
    }

    if (this.imgFile) {
      this.uploadPlaylistImage();
    } else {
      this.savePlaylistData();
    }
  }

  uploadPlaylistImage() {
    this.utvService.uploadImage('playlist', this.imgFile)
      .subscribe(response => {
        this.playlistDataFormGroup.patchValue({
          image: response.url,
        });
        this.savePlaylistData();
      }, (error) => {
        throw error;
      });
  }

  savePlaylistData() {
    const playlistData: any = {};
    Object.keys(this.playlistDataFormGroup.controls).forEach(key => {
      if (key === 'videos') {
        playlistData[key] = this.playlistVideos.map((video, i) => {
          return {
            video_id: video.id,
            position: ++i,
          };
        });
      } else {
        playlistData[key] = this.playlistDataFormGroup.controls[key].value;
      }
    });

    (this.playlist ? this.utvService.editPlaylist(this.playlist.id, playlistData) : this.utvService.createPlaylist(playlistData))
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: (resultPlaylist) => {
          if (this.playlist) {
            this.utvService.changePlaylist(resultPlaylist);
            this.utvService.showNotification(this.translate.instant('utv.playlist_edited'), null, 3000, 'success');
          } else {
            this.utvService.showNotification(this.translate.instant('utv.playlist_edited'), null, 3000, 'success');
            this.utvService.addPlaylist(resultPlaylist);
          }
          this.closeEditPlaylistDialog();
        },
        error: () => {
          this.closeEditPlaylistDialog();
        },
      });
  }

  previewThumbnailImage(files) {
    if (files.length === 0) {
      return;
    }
    this.imgFile = files[0];

    const mimeType = this.imgFile.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    if (this.imgFile.size > UPLOAD_LIMITS.IMG_MAX_SIZE) {
      this.utvService.showNotification(
        this.translate.instant('utv.exceedingFileSize', {
          name: this.imgFile.name,
          size: UPLOAD_LIMITS.IMG_MAX_SIZE_STR,
        }), null, 3000, 'error');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
      this.playlistDataFormGroup.patchValue({
        image: reader.result,
      });
    };
  }

  confirmDeletePlaylistDialog() {
    this.dialog.open(ConfirmModalComponent, {
      data: {
        id: this.playlist.id,
        headerTxt: this.translate.instant('utv.modal.delete'),
        bodyTxt: this.translate.instant('utv.modal.confirm_deleting_playlist'),
        actionPrimary: this.translate.instant('utv.modal.yes_delete'),
        actionSecondary: this.translate.instant('utv.modal.no'),
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.id) {
          this.removePlaylist(result.id);
        }
      });
  }

  removePlaylist(id): void {
    this.utvService.removePlaylist(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response) => {
        this.utvService.removePlaylistById(response.id);
        this.utvService.showNotification(this.translate.instant('utv.playlist_removed'), null, 3000, 'success');
        this.closeEditPlaylistDialog();
      });
  }

  closeEditPlaylistDialog() {
    this.router.navigate(['/utv']);
  }
}
