import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() elementLength: number;
  @Input() itemsPerPage: number = 20;

  @Output() readonly selectedPage = new EventEmitter<number>();

  public pages: number[];
  public activePage: number;

  ngOnInit() {
    this.activePage = 0;
  }

  ngOnChanges(changes): void {
    if (this.elementLength) {
      const pages = Math.ceil(this.elementLength / this.itemsPerPage);
      this.pages = Array(pages).fill(0);
    }

  }

  selectPage(index: number) {
    if (index < 0 || index >= this.pages.length || index === this.activePage) {
      return false;
    }
    this.selectedPage.emit(index * this.itemsPerPage);
    this.activePage = index;
  }
}
