import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mat-menu-option',
  templateUrl: './mat-menu-option.component.html',
  styleUrls: ['./mat-menu-option.component.scss'],
})
export class MatMenuOptionComponent implements OnInit {
  @Input() public option: any;
  @Output() public clickEvent: EventEmitter<MouseEvent>;

  constructor() {
    this.clickEvent = new EventEmitter();
  }

  ngOnInit() {
  }

  @HostListener('click', ['$event']) click(event) {
    this.clickEvent.emit(this.option);
  }
}
