<div class="wrapper">
  <div class="containerLogo">
    <div class="logo">
    </div>
  </div>
  <div class="menuItemsWrapper">
    <ul>
      <li *ngFor="let item of menu" 
          routerLinkActive="active" 
          [routerLink]="['/' + item]">
        <span>{{'menu.' + item | translate}}</span>
      </li>
    </ul>
  </div>
</div>
