<div id="login">
  <div class="loginForm">
    <form id="loginForm"
          name="loginForm"
          (submit)="login(user.email, user.password)"
          *ngIf="!showRestore">
      <div class="containerLogo">
        <div class="logo">
        </div>
      </div>
      <div class="group">
        <div [hidden]="!wrongCredentials"
             class="alert-danger">
             {{'login.errors.credentials' | translate}}
        </div>
        <div [hidden]="email.valid || email.untouched || wrongCredentials"
             class="alert-danger">
             {{'login.errors.email' | translate}}
        </div>
        <input name="email"
               [(ngModel)]="user.email"
               #email="ngModel"
               id="email"
               required
               [pattern]="emailRegexp"
               email
        />
        <span class="highlight"></span>
        <label [hidden]="email.invalid && email.dirty && email.value.length || wrongCredentials" [class.top]="email.value">
          {{'login.email' | translate}}
        </label>
      </div>
      <div class="group">
        <input type="password"
               id="password"
               name="password"
               required
               [(ngModel)]="user.password">
        <span class="highlight"></span>
        <label [class.alert-danger]="wrongCredentials">
          {{'login.password' | translate}}
        </label>
      </div>
      <a (click)="showRestore = true" class="forgot">
        <span>{{'login.forgot' | translate}}</span>
      </a>
      <div class="submitButton">
        <button class="btn signIn" type="submit" [disabled]="email.invalid">
          <span>{{'login.sing_in' | translate}}</span>
        </button>
      </div>
    </form>
    <form id="restoreForm"
          name="restoreForm"
          (submit)="restore(user.email)"
          *ngIf="showRestore">
      <div class="containerLogo">
        <div class="logo">
        </div>
      </div>
      <div class="group">
        <div [hidden]="email.valid || email.untouched" class="alert-danger">
          {{'login.errors.email' | translate}}
        </div>
        <input name="email"
               [(ngModel)]="user.email"
               #email="ngModel"
               id="email"
               required
               [pattern]="emailRegexp"
               email/>
        <span class="highlight"></span>
        <label [hidden]="!email.valid && email.dirty" [class.top]="email.value">
          {{'login.email' | translate}}
        </label>
      </div>
      <div class="submitButton">
        <button class="btn signIn" type="submit" [disabled]="email.invalid">
          <span>{{'login.restore' | translate}}</span>
        </button>
      </div>
    </form>
    <span *ngFor="let message of messages">
      {{message | translate}} <br />
    </span>
  </div>
</div>
<div class="fallback-image"></div>
