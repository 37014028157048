import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
  @Input() index: number;
  @Input() value: string;
  @Input() removable = true;
  @Output() remove = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  removeChip() {
    this.remove.emit(true);
  }
}
